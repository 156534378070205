import { Box, Typography } from "@mui/material";
import React from "react";
import Content from "./Content";
import Title from "./Title";
import { responsiveFeatureFontSize } from "../../../../../styles/Styles";
import GridButtonOnCard from "../../button-on-card/GridButtonOnCard";

const TextOnCard = (props) => {
  const { title, content, id, colorBelow, colorAbove, feature } = props;
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      {feature && (
        <Typography
          fontSize={responsiveFeatureFontSize}
          variant="h3"
          sx={{
            display: "flex",
            fontWeight: "bold",
            color: "#007053",
            justifyContent: { xs: "center" },
          }}
        >
          {feature}
        </Typography>
      )}
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Title title={title} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: "auto",
          flexShrink: 0,
        }}
      >
        <Box sx={{ mb: "10px" }}>
          <Content content={content} />
        </Box>
        <GridButtonOnCard
          id={id}
          colorBelow={colorBelow}
          colorAbove={colorAbove}
        />
      </Box>
    </Box>
  );
};

export default TextOnCard;
