import React from "react";
import { Typography } from "@mui/material";
import { responsiveTitleFontSize } from "../../../../styles/Styles";

const Title = (props) => {
  const { title } = props;
  return (
    <Typography
      fontSize={responsiveTitleFontSize}
      variant="h1"
      sx={{
        fontWeight: "bold",
        color: "#FFFFFF",
        textAlign: { xs: "center", md: "left" },
      }}
    >
      {title}
    </Typography>
  );
};

export default Title;
