import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useContext } from "react";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Products from "./pages/Products";
import ParticularBlog from "./pages/ParticularBlog";
import Admin from "./pages/Admin";
import CreateUpdateBlog from "./pages/CreateUpdateBlog";
import { EditCtxProvider } from "./contexts/EditCtx";
import { RefreshCtxProvider } from "./contexts/RefreshCtx";
import { LoadingCtxProvider } from "./contexts/LoadingCtx";
import LoginAdmin from "./pages/LoginAdmin";
import NotFoundPage from "./pages/NotFoundPage";
import AdminAuthContext from "./contexts/AdminAuthContext";
import Navbar from "./components/navbar/Navbar";
import Blog from "./pages/Blog";
import BackToTopButton from "./components/animation/back-to-top-button/BackToTopButton";
import { Helmet } from "react-helmet";
import Loading from "./pages/Loading";

const MainApp = () => {
  const AdminAuthCtx = useContext(AdminAuthContext);
  return (
    <BrowserRouter>
      <EditCtxProvider>
        <RefreshCtxProvider>
          <LoadingCtxProvider>
            <Helmet>
              <title>Bullard - dụng cụ bảo hộ lao động từ Mỹ </title>
              <meta
                name="description"
                content="An tâm làm việc tại công trường với Bullard đồng hành. Sản phẩm của Bullard đạt chứng nhận an toàn lao động của Mỹ, Châu Âu và Việt Nam. Luôn có ưu đãi. Bảo hành đổi trả dễ dàng. Được phân phối chính hạng tại Việt Nam tại website bullard.vn"
              />
              <meta
                name="keywords"
                content="Mũ điện lực, Mũ cách điện, Mũ mỹ, mũ bảo hộ, nón bảo hộ, Bullard, ANSI, CSA, TCVN, 20KV, 2KV, Aboveview, S51, DUO Safety, SE2, Flexgen"
              />
              <meta name="robots" content="index, follow" />
              <meta name="revisit-after" content="3 days" />
              <meta name="MobileOptimized" content="width" />
              <meta name="HandheldFriendly" content="true" />
              <meta property="og:site_name" content="Bullard tại Việt Nam" />
              <meta property="og:type" content="website" />
              <meta
                property="og:title"
                content="Bullard - dụng cụ bảo hộ lao động từ Mỹ"
              />
              <meta
                property="og:description"
                content="An tâm làm việc tại công trường với Bullard đồng hành. Sản phẩm của Bullard đạt chứng nhận an toàn lao động của Mỹ, Châu Âu và Việt Nam. Luôn có ưu đãi. Bảo hành đổi trả dễ dàng. Cam kết 100% chính hãng."
              />
              <meta name="twitter:card" content="summary_large_image" />
              <meta
                name="twitter:title"
                content="Bullard - dụng cụ bảo hộ lao động từ Mỹ"
              />
              <meta
                name="twitter:description"
                content="An tâm làm việc tại công trường với Bullard đồng hành. Sản phẩm của Bullard đạt chứng nhận an toàn lao động của Mỹ, Châu Âu và Việt Nam. Luôn có ưu đãi. Bảo hành đổi trả dễ dàng. Cam kết 100% chính hãng."
              />
            </Helmet>
            <Navbar />
            <Routes>
              <Route path="/" element={<App />}></Route>
              <Route path="/nimda" element={<LoginAdmin />}></Route>
              <Route
                path="/san-pham/:productName"
                element={<Products />}
              ></Route>
              <Route path="/blog" element={<Blog />}></Route>
              <Route
                path="/blog/:blogId/:title"
                element={<ParticularBlog />}
              ></Route>
              {AdminAuthCtx.logStatus === "loading" ? (
                <Route path="/dashboard/admin" element={<Loading />}></Route>
              ) : AdminAuthCtx.logStatus === "success" ? (
                <Route path="/dashboard/admin" element={<Admin />}></Route>
              ) : AdminAuthCtx.logStatus === "error" ? (
                <Route
                  path="/dashboard/admin"
                  element={<NotFoundPage />}
                ></Route>
              ) : (
                <Route path="/dashboard/admin" element={<NotFoundPage />} />
              )}
              <Route
                path="/dashboard/admin/:modifyType"
                element={<CreateUpdateBlog />}
              ></Route>
              <Route path="*" element={<NotFoundPage />}></Route>
            </Routes>
            <BackToTopButton />
          </LoadingCtxProvider>
        </RefreshCtxProvider>
      </EditCtxProvider>
    </BrowserRouter>
  );
};

export default MainApp;
