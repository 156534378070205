import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import BoldText from "../../layout/BoldText";
import {
  responsiveProductDetailTitleFontSize,
  responsiveProductDetailContentFontSize,
} from "../../../styles/Styles";

const FirstDetail = () => {
  return (
    <Grid
      container
      justifyContent="center"
      spacing={5}
      sx={{ alignItems: "center", mt: 2 }}
    >
      <Grid item xs={12} lg={6}>
        <img
          src="https://res.cloudinary.com/dtkdsevtm/image/upload/v1597915399/images/duo-safety-face_nuuml2.jpg"
          alt="Mặt Trước của Mũ DUO Safety bởi Bullard"
          width="100%"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box display="flex" justifyContent="center">
          <Stack direction="column" justifyContent="space-between" spacing={2}>
            <Typography
              fontSize={responsiveProductDetailTitleFontSize}
              variant="h3"
              sx={{ color: "#2D3748" , fontWeight: "bold"}}
            >
              THIẾT KẾ CẢI TIẾN
            </Typography>
            <Typography
              fontSize={responsiveProductDetailContentFontSize}
              variant="body1"
              sx={{ color: "#646E73" }}
            >
              Khác với mũ dòng S series bán chạy nhất của hãng, mũ DUO Safety
              bởi Bullard được phát triển trong những năm gần đây với cải tiến
              phù hợp với mọi người dùng ở các lĩnh vực khác nhau. Tuy vậy, mũ
              DUO Safety bởi Bullard vẫn giữ được thiết kế đặc trưng kiểu Mỹ,
              khác biệt với các mũ khác tại thị trường Việt Nam.
            </Typography>
            <Typography
              fontSize={responsiveProductDetailContentFontSize}
              variant="body1"
              sx={{ color: "#646E73" }}
            >
              <BoldText>Tối Giản nhưng Chắc Chắn:</BoldText> Mũ tối giản với hai
              đường gân trên mũ và viền cắt mưa giúp tăng độ cứng ép dọc và
              ngang của mũ. Nhờ vành cắt nước mưa và lưỡi trai được thiết kệ
              hoàn hảo, người dùng có tầm nhìn bao quát rộng nhưng đảm bảo che
              chắn an toàn cho toàn bộ khuân mặt.
            </Typography>
            <Typography
              fontSize={responsiveProductDetailContentFontSize}
              variant="body1"
              sx={{ color: "#646E73" }}
            >
              <BoldText>Vật Liệu Nhẹ và Bền Bỉ:</BoldText> Sở hữu thiết kế tối
              giản, Mũ DUO Safety bới Bullard là mũ nhẹ nhất với vật liệu Nhựa
              Polyethylene Mật Độ Cao (HDPE) giúp tăng độ bền lão hóa và sự dẻo
              dai của mũ trong thời gian dài.
            </Typography>
            <Typography
              fontSize={responsiveProductDetailContentFontSize}
              variant="body1"
              sx={{ color: "#646E73" }}
            >
              <BoldText>Phụ Kiện:</BoldText> Hai bên thành mũ là hai khe giúp
              người dùng có thể trang bị thêm phụ kiện bảo vệ kèm theo như bịt
              tai hay mặt kính bảo vệ. Xin liên hệ đại lý Bullard để biết thêm
              chi tiết.
            </Typography>
            <Typography
              fontSize={responsiveProductDetailContentFontSize}
              variant="body1"
              sx={{ color: "#646E73" }}
            >
              <BoldText>Nhận Diện Công Ty:</BoldText> Khu vực nhận diện lớn giúp người dùng có thể tùy chỉnh vị trí logo.
            </Typography>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FirstDetail;
