import { Stack, Typography } from "@mui/material";
import React from "react";
import { responsiveProductDetailContentFontSize } from "../../../styles/Styles";
import ColorText from "../../features/ColorText";
import BoldText from "../../layout/BoldText";

const DetailType3 = () => {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      spacing={2}
      sx={{ width: "100%" }}
    >
      <Typography
        fontSize={responsiveProductDetailContentFontSize}
        variant="body1"
        sx={{ color: "#646E73" }}
      >
        <BoldText>
          <ColorText color="#007053">(Loại 3)</ColorText> Hệ Thống Chốt Gài 4
          điểm cài đúc liền dây cầu mũ.
        </BoldText>
      </Typography>
      <Typography
        fontSize={responsiveProductDetailContentFontSize}
        variant="body1"
        sx={{ color: "#646E73" }}
      >
        <BoldText>Tấm đệm trán:</BoldText> Lớp vải Poly được bọc trên bề mặt
        miếng tạo êm giúp người dùng cảm thấy thoải mái và mềm mại khi sử dụng.
      </Typography>
      <Typography
        fontSize={responsiveProductDetailContentFontSize}
        variant="body1"
        sx={{ color: "#646E73" }}
      >
        <BoldText>Chốt Gài:</BoldText> Cũng sở hữu khoảng chỉnh ngang từ 52 đến
        64cm (6 1/2" - 8"), hệ Chốt Gài đem lại sự tối giản giúp trọng lượng mũ
        sẽ nhẹ và thanh thoát.
      </Typography>
      <Typography
        fontSize={responsiveProductDetailContentFontSize}
        variant="body1"
        sx={{ color: "#646E73" }}
      >
        <BoldText>Dây Cầu Mũ Chữ X:</BoldText> Dây tiêu chuẩn của Bullard là dây
        Nylon bản ngang 1.9cm (3/4"). Đặc biệt, dây được đúc trực tiếp vào 4
        chốt cài ở bốn góc thay vì gài thủ công như các mũ khác trên thị trường
        tạo sự chắc chắn cho người sử dụng. Ở chốt cài ở mỗi góc sẽ có 2 nấc
        khóa giúp người dùng thay đổi độ cao/chiều sâu của lòng mũ một cách dễ
        dàng.
      </Typography>
    </Stack>
  );
};

export default DetailType3;
