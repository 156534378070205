import React from "react";

const CardMedia = (props) => {
  const { img } = props;
  return (
    <img
      width="100%"
      height="auto"
      style={{ objectFit: "contain", margin: "auto" }}
      src={img}
      alt="media"
    ></img>
  );
};
export default CardMedia;
