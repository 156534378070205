import {
  Box,
  Card,
  Grid,
  Stack,
  styled,
  Typography,
  ThemeProvider,
  Divider,
} from "@mui/material";
import React, { useEffect } from "react";
import Layout from "../layout/Layout";
import ItemGrid from "./ItemGrid";
import DetailGlasses from "./DetailGlasses";
import theme from "../../styles/Styles";
import {
  responsiveProductDetailTitleFontSize,
  responsiveProductDetailContentFontSize,
  responsiveDetailFontSize,
} from "../../styles/Styles";
import FirstDetail from "./detail-grid/FirstDetail";
import RepresentProduct from "../represent-product/RepresentProduct";
import HorizontalScroll from "../slider/HorizontalScroll";
import { GlassesDetailImages } from "../slider/ImageData";
import AdBanner from "../smart-ad-banner/AdBanner";

const CardMainHeader = styled(Stack)({
  direction: "column",
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
});

const SE2Content = () => {
  const bhldLink =
    "https://baoholaodong.com/san-pham/kinh-bao-ho-bullard-se2-caf-trong-suot-chong-xuoc-dong-suong-uv/?utm_source=bvn";
  const tikiLink =
    "https://tiki.vn/kinh-bao-ho-chong-bui-tray-xuoc-hap-hoi-cao-cap-se2-bullard-my-den-khoi-p104599793.html";

  useEffect(() => {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Layout>
          <RepresentProduct
            title="KÍNH BẢO HỘ SE2"
            name="kinh-bao-ho-bullard-se2"
            imageSrc="https://res.cloudinary.com/dtkdsevtm/image/upload/v1605201286/images/se2-main-mobile_fq6rax.jpg"
            alt="Kính bảo hộ SE2 từ Mỹ | Chông xước, Chống Đọng Nước và Chống Va Đập"
            content="Núm vặn mới do Bullard thiết kế giúp dễ dàng thao tác hơn so với các thế hệ trước, tạo sự thoải mái và tiện lợi cho người dùng."
            bhldLink={bhldLink}
            tikiLink={tikiLink}
          />
          <Divider />
          <Stack direction="column" justifyContent="center" sx={{ mb: "30px" }}>
            {/* Detail 1 */}
            <Stack direction="column">
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={5}
                sx={{ mt: 5 }}
              >
                <FirstDetail />
              </Stack>
            </Stack>
            {/* Detail 2 */}
            <Stack
              direction="column"
              justifyContent="space-between"
              spacing={5}
              sx={{ mt: 8 }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    fontSize={responsiveProductDetailTitleFontSize}
                    variant="h3"
                    sx={{ color: "#2D3748", marginBottom: 0.5 , fontWeight: "bold"}}
                  >
                    BẢO VỆ MẮT CỦA BẠN
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    fontSize={responsiveProductDetailContentFontSize}
                    variant="body1"
                    sx={{ color: "#646E73" }}
                  >
                    Toàn bộ kính nhập khẩu đều được kiểm tra chất lượng, có
                    chứng nhận hợp quy, đạt tiêu chuẩn và quy chuẩn theo quy
                    định của chính phủ Việt Nam. Kính Bullard SE2 đạt tiêu chuẩn
                    Hoa Kì ANSI/ISEA Z87.1 cho kính bảo hộ.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container textAlign="center">
                <ItemGrid
                  content="NGĂN 99.99% TIA UV"
                  iconSrc="https://www.bullard.vn/images/UV_icon.jpg"
                  alt="Bullard kính SE2 ngăn 99.99% tia UV"
                />
                <ItemGrid
                  content="QUANG HỌC CHÍNH XÁC"
                  iconSrc="https://www.bullard.vn/images/Bullard_Safety-Glassess_Icon_Optically-Correct.jpg"
                  alt="Bullard kính SE2 quang học chính xác"
                />
                <ItemGrid
                  content="T/C ANSI 97.1+ VA ĐẬP TỐC ĐỘ CAO"
                  iconSrc="https://www.bullard.vn/images/High_Velocity_Impact_icon.jpg"
                  alt="Bullard kính SE2 T/C ANSI 97.1+ va đập tốc độ cao"
                />
                <ItemGrid
                  content="T/C ANSI 97.1+ KHÁNG VA ĐẬP KHỐI LƯỢNG LỚN"
                  iconSrc="https://www.bullard.vn/images/High_Mass_Electricity_Resistant_icon.jpg"
                  alt="Bullard kính SE2 T/C ANSI 97.1+ kháng va đập khối lượng lớn"
                />
                <ItemGrid
                  content="CHỐNG XƯỚC"
                  iconSrc="https://www.bullard.vn/images/Anti_Scratch_icon.jpg"
                  alt="Bullard kính SE2 chống xước"
                />
                <ItemGrid
                  content="CHỐNG ĐỌNG SƯƠNG"
                  iconSrc="https://www.bullard.vn/images/Anti_Fog_icon.jpg"
                  alt="Bullard kính SE2 chống đọng sương"
                />
              </Grid>
              <Stack direction="column" sx={{ mt: 8 }}>
                <Box sx={{ textAlign: "left" }}>
                  <Typography
                    fontSize={responsiveProductDetailTitleFontSize}
                    variant="h3"
                    sx={{ color: "#2D3748", mb: 0.5 , fontWeight: "bold"}}
                  >
                    CHI TIẾT
                  </Typography>
                </Box>
                <Box sx={{ display: { xs: "none", sm: "block" } }}>
                  <img
                    src="https://res.cloudinary.com/dtkdsevtm/image/upload/v1605201286/images/se2-detail_yjqquq.jpg"
                    alt="Chi Tiết của Kính bảo hộ SE2 từ Mỹ | Chống xước, Chống Đọng Nước và Chống Va Đập"
                    width="100%"
                    object-fit="contain"
                  />
                </Box>
                <Box sx={{ display: { xs: "block", sm: "none" } }}>
                  <HorizontalScroll
                    items={GlassesDetailImages}
                    height="450px"
                    padding="0px"
                  />
                  <Typography
                    fontSize={responsiveDetailFontSize}
                    variant="body1"
                    sx={{ color: "#646E73", textAlign: "center" }}
                  >
                    Chạm và kéo sang hai bên để xem toàn ảnh
                  </Typography>
                </Box>
              </Stack>
              <AdBanner type={["hard-hat", "glasses"]} />
              {/* Detail 3 */}
              <Grid container spacing={0} sx={{ mt: 8 }}>
                <Grid item xs={12}>
                  <Box sx={{ textAlign: "left" }}>
                    <Typography
                      fontSize={responsiveProductDetailTitleFontSize}
                      variant="h3"
                      sx={{ color: "#2D3748", mb: 0.5 , fontWeight: "bold"}}
                    >
                      MÀU SẮC
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  justifyContent="center"
                  textAlign="center"
                >
                  <Card elevation={0}>
                    <CardMainHeader>
                      <Box display="inline-block" textAlign="center">
                        <img
                          src="https://res.cloudinary.com/dtkdsevtm/image/upload/v1605201286/images/SE2CAF_v7hi3m.png"
                          alt="Kính Bảo Hộ SE2 Trong Suốt"
                          width="100%"
                        />
                      </Box>
                      <Box>
                        <DetailGlasses
                          pri1="Màu sắc"
                          sec1="Trắng"
                          pri2="Mã hàng"
                          sec2="SE2CAF"
                          detail="SE2, Mắt Kính UV385 AF Trong Suốt"
                        />
                      </Box>
                    </CardMainHeader>
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  justifyContent="center"
                  textAlign="center"
                >
                  <Card elevation={0}>
                    <CardMainHeader>
                      <Box display="inline-block" textAlign="center">
                        <img
                          src="https://res.cloudinary.com/dtkdsevtm/image/upload/v1605201286/images/SE2SAF_ulw6os.png"
                          alt="Kính Bảo Hộ SE2 Ghi Khói"
                          width="100%"
                        />
                      </Box>
                      <Box>
                        <DetailGlasses
                          pri1="Màu sắc"
                          sec1="Ghi Khói"
                          pri2="Mã hàng"
                          sec2="SE2SAF"
                          detail="SE2, Mắt Kính UV385 AF Ghi Khói"
                        />
                      </Box>
                    </CardMainHeader>
                  </Card>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </Layout>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default SE2Content;
