import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Footer from "../components/footer/Footer";
import Layout from "../components/layout/Layout";
import { ThemeProvider } from "@mui/material/styles";
import theme, { responsiveContentFooterFontSize } from "../styles/Styles";
import CardBlogMain from "../components/blogs/blog-card/CardBlogMain";
import { getDocs, collection } from "firebase/firestore";
import { database } from "../firebase-lib/initFireBase";
import BoldText from "../components/layout/BoldText";
import Intro from "../components/landing-page/noti/Intro";
import LoadingCtx from "../contexts/LoadingCtx";
import CircularProgress from "@mui/material/CircularProgress";
import { Helmet } from "react-helmet";

const contentFooter = (
  <Typography fontSize={responsiveContentFooterFontSize} variant="body1">
    Bullard là thương hiệu đến từ Mỹ chuyền về trang thiết bị an toàn lao động.
    Thiết kế của Bullard luôn hướng tới tính{" "}
    <BoldText> an toàn cao, bền bỉ, và hiện đại</BoldText>
  </Typography>
);

const Blog = () => {
  const loadingCtx = useContext(LoadingCtx);
  const [blog, setBlog] = useState([]);

  const getBlog = async () => {
    const blogCollectionRef = collection(database, "blogs");
    loadingCtx.setIsLoading(true);
    await getDocs(blogCollectionRef)
      .then((response) => {
        const myBlogs = response.docs
          .map((doc) => ({
            data: doc.data(),
            id: doc.id,
          }))
          .filter((blog) => blog.data.archive === false);
        setBlog(myBlogs);
      })
      .catch((error) => {
        alert(error);
      });
    loadingCtx.setIsLoading(false);
  };
  useEffect(() => {
    getBlog();
  }, []);

  useEffect(() => {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>
            Bullard Blog - chỉ dẫn và giới thiêu thông tin hữu ích về bảo hộ lao
            động
          </title>
          <meta
            name="description"
            content="Từ Mỹ, Bullard có 120 năm kinh nghiệm trong sản xuất thiết bị bảo hộ lao động. Đọc những bài viết của Bullard để có thêm kiến thức và lời khuyên về bảo hộ lao động"
          />
          <meta
            name="keywords"
            content="Bullard blog, mũ bảo hộ, mũ bullard, mũ cách điện, lịch sử bullard, bảo hộ lao động"
          />
          <meta name="robots" content="index, follow" />
          <meta name="revisit-after" content="3 days" />
          <meta name="MobileOptimized" content="width" />
          <meta name="HandheldFriendly" content="true" />
          <meta property="og:site_name" content="Bullard Blog" />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content="Bullard Blog - chỉ dẫn và giới thiêu thông tin hữu ích về bảo hộ lao động"
          />
          <meta
            property="og:description"
            content="Từ Mỹ, Bullard có 120 năm kinh nghiệm trong sản xuất thiết bị bảo hộ lao động. Đọc những bài viết của Bullard để có thêm kiến thức và lời khuyên về bảo hộ lao động."
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Bullard Blog - chỉ dẫn và giới thiêu thông tin hữu ích về bảo hộ lao động"
          />
          <meta
            name="twitter:description"
            content="Từ Mỹ, Bullard có 120 năm kinh nghiệm trong sản xuất thiết bị bảo hộ lao động. Đọc những bài viết của Bullard để có thêm kiến thức và lời khuyên về bảo hộ lao động."
          />
        </Helmet>
        <Intro content="Blog" pos="left" />
        <Box sx={{ mt: 5, mb: 5 }}>
          <Layout marginTop="3rem">
            {loadingCtx.isLoading === false ? (
              <Grid container spacing={4} sx={{ justifyContent: "center" }}>
                {blog.map((post) => (
                  <Grid item xs={11} sm={11} md={6} lg={4} key={post.id}>
                    <CardBlogMain
                      key={post.id}
                      id={post.id}
                      img={post.data.img}
                      category={post.data.category}
                      title={post.data.title}
                      subtitle={post.data.subtitle}
                      date={post.data.date}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box sx={{ textAlign: "center" }}>
                <CircularProgress />
              </Box>
            )}
          </Layout>
        </Box>
        <Layout></Layout>
        <Footer content={contentFooter} quote="" />
      </ThemeProvider>
    </React.Fragment>
  );
};

export default Blog;
