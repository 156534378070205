import { createContext, useState } from "react";

const RefreshCtx = createContext({ isModify: false, setIsModify: () => {} });

export const RefreshCtxProvider = (props) => {
  const [modify, setModify] = useState(false);
  const setIsModifyHandler = (status) => {
    setModify(status);
  };
  return (
    <RefreshCtx.Provider
      value={{
        isModify: modify,
        setIsModify: setIsModifyHandler,
      }}
    >
      {props.children}
    </RefreshCtx.Provider>
  );
};

export default RefreshCtx;
