import * as React from "react";
import { Box, Divider, Drawer, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import {
  responsiveDetailFontSize,
  responsiveFeatureFontSize,
} from "../../styles/Styles";

const MenuBurger = () => {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const dividerSX = {
    mt: "15px",
    mb: "15px",
  };
  const listGridSX = { gap: "0px" };
  const navLinkStyles = ({ isActive }) => {
    return {
      color: isActive ? "#007053" : "#AEB0B4",
      textDecoration: isActive ? "none" : "none",
      fontWeight: isActive ? "bold" : "normal",
    };
  };

  const onLogoClickHandler = () => {
    navigate("/");
    document.body.scrollTo({ top: 0, behavior: "smooth" });
    setIsDrawerOpen(false);
  };

  return (
    <React.Fragment>
      <MenuIcon
        onClick={() => {
          setIsDrawerOpen(true);
        }}
        color="primary"
      />
      <Drawer
        anchor="top"
        // variant="temporary"
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
      >
        <Box textAlign="left">
          <CloseIcon
            fontSize="large"
            onClick={() => {
              setIsDrawerOpen(false);
            }}
          />
        </Box>
        <Box
          sx={{
            paddingTop: 1,
            paddingLeft: 5,
            paddingRight: 5,
            paddingBottom: 5,
            margin: 0,
          }}
        >
          <Grid container spacing={2.5}>
            <Grid item xs={12}>
              <Box
                sx={{
                  width: "140px",
                  display: "inline-block",
                  textAlign: "center",
                  justifyContent: "center",
                  mt: 2,
                  mb: 2,
                }}
                onClick={onLogoClickHandler}
              >
                <img
                  src="https://www.bullard.vn/images/bullard_logo.png"
                  alt="logo"
                  width="100%"
                  height="auto"
                  style={{ objectFit: "contain" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize={responsiveFeatureFontSize} variant="h3">
                MŨ BẢO HỘ
              </Typography>
            </Grid>
            <Grid item xs={12} container sx={listGridSX}>
              <Grid item xs={4}>
                <NavLink
                  onClick={() => {
                    setIsDrawerOpen(false);
                  }}
                  style={navLinkStyles}
                  to="/san-pham/mu-bao-ho-bullard-s51"
                >
                  <Typography
                    fontSize={responsiveDetailFontSize}
                    variant="body1"
                    sx={{ padding: "10px" }}
                  >
                    MŨ BẢO HỘ S51
                  </Typography>
                </NavLink>
              </Grid>
              <Grid item xs={4}>
                <NavLink
                  onClick={() => {
                    setIsDrawerOpen(false);
                  }}
                  style={navLinkStyles}
                  to="/san-pham/mu-bao-ho-bullard-duo-safety-c422"
                >
                  <Typography
                    fontSize={responsiveDetailFontSize}
                    variant="body1"
                    sx={{ padding: "10px" }}
                  >
                    MŨ BẢO HỘ DUO SAFETY
                  </Typography>
                </NavLink>
              </Grid>
              <Grid item xs={4}>
                <NavLink
                  onClick={() => {
                    setIsDrawerOpen(false);
                  }}
                  style={navLinkStyles}
                  to="/san-pham/flexgen"
                >
                  <Typography
                    fontSize={responsiveDetailFontSize}
                    variant="body1"
                    sx={{ padding: "10px" }}
                  >
                    TẤT CẢ CÁC SẢN PHẨM MŨ BẢO HỘ
                  </Typography>
                </NavLink>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={dividerSX} />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize={responsiveFeatureFontSize} variant="h3">
                KÍNH BẢO HỘ
              </Typography>
            </Grid>
            <Grid item xs={12} container sx={listGridSX}>
              <Grid item xs={6}>
                <NavLink
                  onClick={() => {
                    setIsDrawerOpen(false);
                  }}
                  style={navLinkStyles}
                  to="/san-pham/kinh-bao-ho-bullard-se2"
                >
                  <Typography
                    fontSize={responsiveDetailFontSize}
                    variant="body1"
                    sx={{ paddingLeft: "10px" }}
                  >
                    KÍNH BẢO HỘ SE2
                  </Typography>
                </NavLink>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={dividerSX} />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize={responsiveFeatureFontSize} variant="h3">
                BLOG
              </Typography>
            </Grid>
            <Grid item xs={12} container sx={listGridSX}>
              <Grid item xs={4}>
                <NavLink
                  onClick={() => {
                    setIsDrawerOpen(false);
                  }}
                  style={navLinkStyles}
                  to="/blog"
                >
                  <Typography
                    fontSize={responsiveDetailFontSize}
                    variant="body1"
                    sx={{ padding: "10px" }}
                  >
                    BLOG
                  </Typography>
                </NavLink>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default MenuBurger;
