import { useRef, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import {
  responsiveProductDetailTitleFontSize,
  responsiveProductDetailContentFontSize,
  responsiveColorFontSize,
} from "../../styles/Styles";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Slider from "react-slick";

const images = {
  0: {
    id: "Xanh Pacific",
    src: "https://res.cloudinary.com/dtkdsevtm/image/upload/v1627375033/images/s51-pacific-blue_kfe3vt.jpg",
  },
  1: {
    id: "Trắng",
    src: "https://res.cloudinary.com/dtkdsevtm/image/upload/v1597918835/images/s51-trang-white_mn2nec.jpg",
  },
  2: {
    id: "Đỏ",
    src: "https://res.cloudinary.com/dtkdsevtm/image/upload/v1597918834/images/s51-do-red_ntem71.jpg",
  },
  3: {
    id: "Vàng",
    src: "https://res.cloudinary.com/dtkdsevtm/image/upload/v1597918835/images/s51-vang-yellow_xykqhj.jpg",
  },
};
const S51Color = () => {
  const [borderColorBlue, setBorderColorBlue] = useState("#007053");
  const [borderColorWhite, setBorderColorWhite] = useState("#bbc1c4");
  const [borderColorRed, setBorderColorRed] = useState("#bbc1c4");
  const [borderColorYellow, setBorderColorYellow] = useState("#bbc1c4");
  const slider = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const settings = {
    className: "center",
    dots: true,
    centerMode: true,
    focusOnSelect: false,
    arrows: false,
    infinite: true,
    speed: 500,
    centerPadding: "420px",
    swipeToSlide: true,
    slidesToShow: 1,
    cssEase: "ease-out",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "240px",
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "160px",
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "120px",
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "60px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "30px",
        },
      },
    ],
    afterChange: (currentSlide) => {
      setCurrentSlide(currentSlide);
      for (let i = 0; i < Object.keys(images).length; i++) {
        if (currentSlide === i) {
          if (images[i]["id"] === "Xanh Pacific") {
            onBlueClickHandler();
          }
          if (images[i]["id"] === "Trắng") {
            onWhiteClickHandler();
          }
          if (images[i]["id"] === "Đỏ") {
            onRedClickHandler();
          }
          if (images[i]["id"] === "Vàng") {
            onYellowClickHandler();
          }
        }
      }
      // console.log("current slide:", currentSlide);
    },
  };
  const onNextHandler = () => {
    slider?.current?.slickNext();
  };
  const onPrevHandler = () => {
    slider?.current?.slickPrev();
  };
  const colorBoxSX = {
    mt: 3.5,
    mb: 3.5,
    display: "flex",
    flexDirection: "row",
    gap: { xs: "10px", sm: "15px", lg: "30px" },
    justifyContent: "center",
  };
  const onBlueClickHandler = () => {
    setBorderColorBlue("#007053");
    if (borderColorWhite === "#007053") {
      setBorderColorWhite("#bbc1c4");
    }
    if (borderColorRed === "#007053") {
      setBorderColorRed("#bbc1c4");
    }
    if (borderColorYellow === "#007053") {
      setBorderColorYellow("#bbc1c4");
    }
    slider?.current?.slickGoTo(0);
  };
  const onWhiteClickHandler = () => {
    setBorderColorWhite("#007053");
    if (borderColorBlue === "#007053") {
      setBorderColorBlue("#bbc1c4");
    }
    if (borderColorRed === "#007053") {
      setBorderColorRed("#bbc1c4");
    }
    if (borderColorYellow === "#007053") {
      setBorderColorYellow("#bbc1c4");
    }
    slider?.current?.slickGoTo(1);
  };
  const onRedClickHandler = () => {
    setBorderColorRed("#007053");
    if (borderColorBlue === "#007053") {
      setBorderColorBlue("#bbc1c4");
    }
    if (borderColorWhite === "#007053") {
      setBorderColorWhite("#bbc1c4");
    }
    if (borderColorYellow === "#007053") {
      setBorderColorYellow("#bbc1c4");
    }
    slider?.current?.slickGoTo(2);
  };
  const onYellowClickHandler = () => {
    setBorderColorYellow("#007053");
    if (borderColorBlue === "#007053") {
      setBorderColorBlue("#bbc1c4");
    }
    if (borderColorWhite === "#007053") {
      setBorderColorWhite("#bbc1c4");
    }
    if (borderColorRed === "#007053") {
      setBorderColorRed("#bbc1c4");
    }
    slider?.current?.slickGoTo(3);
  };
  return (
    <Grid
      container
      justifyContent="center"
      spacing={5}
      sx={{ alignItems: "center", mt: 8, mb: 8 }}
    >
      <Grid item xs={12} md={12}>
        <Typography
          fontSize={responsiveProductDetailTitleFontSize}
          variant="h3"
          sx={{ color: "#2D3748", textAlign: "center" , fontWeight: "bold"}}
        >
          MÀU SẮC
        </Typography>
        <Typography
          fontSize={responsiveProductDetailContentFontSize}
          variant="body1"
          sx={{
            color: "#646E73",
            mt: 0.5,
            textAlign: "center",
          }}
        >
          Bullard tại Việt Nam xin hân hạnh giới thiệu bốn màu của dòng Mũ
          Bullard S51
        </Typography>
        <Box sx={colorBoxSX}>
          <Typography
            fontSize={responsiveColorFontSize}
            variant="body1"
            sx={{
              color: "#646E73",
              fontWeight: "bold",
              textAlign: "center",
              pl: "10px",
              pr: "10px",
              border: `2px solid ${borderColorBlue}`,
              "&:hover": {
                cursor: "pointer",
              },
              borderRadius: "5px",
            }}
            onClick={onBlueClickHandler}
          >
            Xanh Pacific
          </Typography>
          <Typography
            fontSize={responsiveColorFontSize}
            variant="body1"
            sx={{
              color: "#646E73",
              fontWeight: "bold",
              textAlign: "center",
              pl: "10px",
              pr: "10px",
              border: `2px solid ${borderColorWhite}`,
              "&:hover": {
                cursor: "pointer",
              },
              borderRadius: "5px",
            }}
            onClick={onWhiteClickHandler}
          >
            Trắng
          </Typography>
          <Typography
            fontSize={responsiveColorFontSize}
            variant="body1"
            sx={{
              color: "#646E73",
              fontWeight: "bold",
              textAlign: "center",
              pl: "10px",
              pr: "10px",
              border: `2px solid ${borderColorRed}`,
              "&:hover": {
                cursor: "pointer",
              },
              borderRadius: "5px",
            }}
            onClick={onRedClickHandler}
          >
            Đỏ
          </Typography>
          <Typography
            fontSize={responsiveColorFontSize}
            variant="body1"
            sx={{
              color: "#646E73",
              fontWeight: "bold",
              textAlign: "center",
              pl: "10px",
              pr: "10px",
              border: `2px solid ${borderColorYellow}`,
              "&:hover": {
                cursor: "pointer",
              },
              borderRadius: "5px",
            }}
            onClick={onYellowClickHandler}
          >
            Vàng
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box
            flexGrow={1}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Button onClick={onPrevHandler}>
              <ArrowBackIosNewIcon fontSize="large" />
            </Button>
          </Box>
          <Box
            flexGrow={1}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button onClick={onNextHandler}>
              <ArrowForwardIosIcon fontSize="large" />
            </Button>
          </Box>
        </Box>
        <Slider ref={slider} {...settings}>
          <Box
            sx={{
              pl: { xs: "5px", md: "30px" },
              pr: { xs: "5px", md: "30px" },
            }}
          >
            <img
              src="https://res.cloudinary.com/dtkdsevtm/image/upload/v1627375033/images/s51-pacific-blue_kfe3vt.jpg"
              alt="Bullard S51 Xanh Pacific"
              width="100%"
            />
          </Box>
          <Box
            sx={{
              pl: { xs: "5px", md: "30px" },
              pr: { xs: "5px", md: "30px" },
            }}
          >
            <img
              src="https://res.cloudinary.com/dtkdsevtm/image/upload/v1597918835/images/s51-trang-white_mn2nec.jpg"
              alt="Bullard S51 Trắng"
              width="100%"
            />
          </Box>
          <Box
            sx={{
              pl: { xs: "5px", md: "30px" },
              pr: { xs: "5px", md: "30px" },
            }}
          >
            <img
              src="https://res.cloudinary.com/dtkdsevtm/image/upload/v1597918834/images/s51-do-red_ntem71.jpg"
              alt="Bullard S51 Đỏ"
              width="100%"
            />
          </Box>
          <Box
            sx={{
              pl: { xs: "5px", md: "30px" },
              pr: { xs: "5px", md: "30px" },
            }}
          >
            <img
              src="https://res.cloudinary.com/dtkdsevtm/image/upload/v1597918835/images/s51-vang-yellow_xykqhj.jpg"
              alt="Bullard S51 Vàng"
              width="100%"
            />
          </Box>
        </Slider>
      </Grid>
    </Grid>
  );
};

export default S51Color;
