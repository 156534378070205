import React from "react";
import { Box, Card, styled } from "@mui/material";
import CardMedia from "./CardMedia";
import CardDate from "./CardDate";
import CardContent from "./CardContent";
import { useNavigate } from "react-router-dom";
const CardBlogMain = (props) => {
  const navigate = useNavigate();
  const { id, img, category, title, subtitle, date } = props;
  const CardContainer = styled(Card)({
    height: "100%",
    width: "100%",
    "&:hover": {
      cursor: "pointer",
    },
  });
  const CardHeaderContainer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
    width: "100%",
  });
  const CardDateContainer = styled(Box)({
    width: "100%",
    backgroundColor: "none",
    paddingLeft: "15px",
    paddingRight: "15px",
    marginTop: "10px",
  });
  const CardContentContainer = styled(Box)({
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "pre-line",
    width: "100%",
    backgroundColor: "none",
  });
  const newTitle = title.toLowerCase().replace(/\s/g, "-");
  return (
    <React.Fragment>
      <CardContainer
        onClick={() => {
          document.body.scrollTo({ top: 0, behavior: "smooth" });
          navigate(`/blog/${id}/${newTitle}`);
        }}
      >
        <CardHeaderContainer>
          <CardMedia img={img} />
        </CardHeaderContainer>
        <CardDateContainer>
          <CardDate date={date} />
        </CardDateContainer>
        <CardContentContainer>
          <CardContent title={title} subtitle={subtitle} category={category} />
        </CardContentContainer>
      </CardContainer>
    </React.Fragment>
  );
};

export default CardBlogMain;
