import { Stack } from "@mui/material";
import React from "react";
import LineButton from "./LineButton";
import RoundButton from "./RoundButton";

const ButtonGroup = (props) => {
  const { id, equivalent } = props;
  return (
    <Stack direction="row" spacing={3} sx = {{justifyContent: {xs: 'center', md: 'left'}}}>
      <LineButton id={equivalent} />
      <RoundButton id={id} />
    </Stack>
  );
};

export default ButtonGroup;
