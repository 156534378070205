import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../../styles/Styles";
import ContentLayout from "../../layout/ContentLayout";
import { database } from "../../../firebase-lib/initFireBase";
import { addDoc, collection } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import { quillModules } from "../../rich_text_editor/QuillEditor";
import "react-quill/dist/quill.snow.css";

const CreateBlog = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [date, setDate] = useState("");
  const [category, setCategory] = useState("");
  const [content, setContent] = useState("");
  const [img, setImg] = useState("");
  const [currentChoice, setCurrentChoice] = useState(false);

  const archiveChoice = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const buttonSX = {
    "&.MuiButtonBase-root:hover": {
      background: "none",
    },
  };

  const onChangeTitleHandler = (event) => {
    setTitle(event.target.value);
  };
  const onChangeSubtitleHandler = (event) => {
    setSubtitle(event.target.value);
  };
  const onChangeCategoryHandler = (event) => {
    setCategory(event.target.value);
  };
  const onChangeDateHandler = (event) => {
    setDate(event.target.value);
  };
  const onChangeImageHandler = (event) => {
    setImg(event.target.value);
  };
  // const onGetDataHandler = (data) => {
  //   setContent(data);
  // };
  // console.log(content);

  const onSubmitHandler = (event) => {
    event.preventDefault();

    if (title === "" || date === "" || category === "" || content === "") {
      alert("Empty fill!");
      return;
    }
    const blogCollectionRef = collection(database, "blogs");
    addDoc(blogCollectionRef, {
      title,
      subtitle,
      img,
      date,
      category,
      content,
      archive: currentChoice,
    }).catch((error) => {
      alert(error);
    });
    navigate("/dashboard/admin", { replace: true });
  };

  const onBackHandler = () => {
    navigate("/dashboard/admin");
  };

  const handleChange = (event) => {
    setCurrentChoice(event.target.value);
  };

  return (
    <ContentLayout>
      <ThemeProvider theme={theme}>
        <form onSubmit={onSubmitHandler}>
          <Card>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                  <Typography
                    variant="h1"
                    color="primary"
                    sx={{ fontSize: "48px" }}
                  >
                    Create your new blog
                  </Typography>
                </Grid>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                  <TextField
                    label="Title"
                    placeholder="Enter title"
                    fullWidth
                    onChange={onChangeTitleHandler}
                  ></TextField>
                </Grid>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                  <TextField
                    label="Subtitle"
                    placeholder="Enter subtitle"
                    fullWidth
                    onChange={onChangeSubtitleHandler}
                  ></TextField>
                </Grid>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                  <TextField
                    id="date"
                    label="Date"
                    type="date"
                    defaultValue={new Date().toString()}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={onChangeDateHandler}
                  />
                </Grid>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                  <TextField
                    label="Category"
                    placeholder="Enter category"
                    fullWidth
                    onChange={onChangeCategoryHandler}
                  ></TextField>
                </Grid>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                  <TextField
                    label="Image URL"
                    placeholder="Paste your image here"
                    fullWidth
                    onChange={onChangeImageHandler}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h2"
                    sx={{ fontSize: 24, fontWeight: "bold" }}
                  >
                    Content
                  </Typography>
                  <ReactQuill
                    modules={quillModules}
                    theme="snow"
                    onChange={setContent}
                    placeholder="Content goes here..."
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Archive or not?"
                    value={currentChoice}
                    onChange={handleChange}
                    helperText="Please select your choice"
                    fullWidth
                  >
                    {archiveChoice.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                  <Stack direction="row" justifyContent="space-between">
                    <Button sx={buttonSX} onClick={onBackHandler}>
                      <Typography variant="h6" sx={{ fontSize: "24px" }}>
                        BACK
                      </Typography>
                    </Button>
                    <Button type="submit" sx={buttonSX}>
                      <Typography variant="h6" sx={{ fontSize: "24px" }}>
                        SUBMIT
                      </Typography>
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      </ThemeProvider>
    </ContentLayout>
  );
};

export default CreateBlog;
