import { Box, Typography } from "@mui/material";
import React from "react";
import { responsiveWorkHourFontSize } from "../../../styles/Styles";

const WorkHour = () => {
  return (
    <Box
      position="static"
      sx={{
        textAlign: "center",
        backgroundColor: "#F1F1F1",
        display: "flex",
        justifyContent: "center",
        p: 1,
      }}
    >
      <Typography
        fontSize={responsiveWorkHourFontSize}
        variant="body1"
        sx={{ color: "#282829" }}
      >
        Chúng tôi mở cửa từ Thứ Hai đến Thứ Bảy 7h30 - 17h30
      </Typography>
    </Box>
  );
};

export default WorkHour;
