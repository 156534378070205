import React, { useEffect } from "react";
import { Stack, Divider } from "@mui/material";
import Layout from "../layout/Layout";
import theme from "../../styles/Styles";
import { ThemeProvider } from "@mui/material";
import FirstDetail from "./detail-grid/FirstDetail";
import ThirdDetail from "./detail-grid/ThirdDetail";
import FifthDetail from "./detail-grid/FifthDetail";
import DUOColor from "./DUOColor";
import RepresentProduct from "../represent-product/RepresentProduct";
import AdBanner from "../smart-ad-banner/AdBanner";
import MuDUO from "../image/muxanh.png";
import SecondDetailList from "../features/SecondDetailList";
import FourthDetailList from "../features/FourthDetailList";
import Dayquaixanh from "../image/daycai-bullard-xanh.jpg";
import Dayquaitrang from "../image/daycai-bullard-trang.jpg";
import Dayquaivang from "../image/daycai-bullard-vang.jpg";
import Flexgear from "../image/flexgear-bullard.jpg";
import Chotgai from "../image/chotgai-bullard.jpg";
import Numvan from "../image/numvan-bullard.jpg";

const prods = [
  {
    title: "Núm vặn FlexGen™",
    subtitle: "Nhẹ. Dễ thao tác. Thiết kế mới",
    imgSrc: `${Numvan}`,
    alt: "Cầu Mũ FlexGen - Mũ Duo Safety bởi Bullard",
    type: "1",
  },
  {
    title: "Núm vặn Flex-Gear®",
    subtitle: "To bản. Bền lâu. Chắc chắn",
    imgSrc: `${Flexgear}`,
    alt: "Cầu Mũ FlexGear - Mũ Duo Safety bởi Bullard",
    type: "2",
  },
  {
    title: "Chốt gài",
    subtitle: "Tối giản. Tiết kiệm",
    imgSrc: `${Chotgai}`,
    alt: "Cầu Mũ Chốt Gài - Mũ Duo Safety bởi Bullard",
    type: "3",
  }
];
const prods2 = [
  {
    imgSrc: `${Dayquaitrang}`,
    alt: "Dây Quai Cầu Sập của Mũ DUO Safety bởi Bullard",
    content: {
      main: "Cầu Sập",
      submain:
        ": Mũ DUO Safety bởi Bullard trang bị khóa Cầu Sập tăng độ chắc chắn cho dây quai.",
    },
    hidden: false,
  },
  {
    imgSrc: `${Dayquaixanh}`,
    alt: "Dây Quai Móc Cài của Mũ DUO Safety bởi Bullard",
    content: {
      main: "Khoá Móc Cài",
      submain:
        ": Dây quai Khóa Móc Cài được thiết kế tối ưu cho việc dễ tháo lắp và tối đa khoảng sử dụng để lắp thêm phụ kiện.",
    },
    hidden: false,
  },
  {
    imgSrc: `${Dayquaivang}`,
    alt: "Dây Quai Móc Bấm của Mũ DUO Safety bởi Bullard",
    content: {
      main: "[NGƯNG SẢN XUẤT] Khoá Móc Bấm",
      submain:
        ": Khóa Móc Bấm có thiết kế tối giản và dễ sử dụng.",
    },
    hidden: false,
  }
];

const DUOContent = () => {
  const bhldLink = "https://baoholaodong.com/nhan-hang/bullard/?utm_source=bvn";
  const tikiLink =
    "https://tiki.vn/non-bao-ho-bullard-duo-safety-num-van-xuat-xu-hoa-ki-hang-chinh-hang-p105026637.html?spid=105026643";
  useEffect(() => {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Layout>
          <RepresentProduct
            title="MŨ BẢO HỘ DUO SAFETY C422"
            name="mu-bao-ho-bullard-duo-safety-c422"
            imageSrc={MuDUO}
            alt="Mũ DUO Safety bởi Bullard | Thiết bị an toàn từ Mỹ - Hơn 120 năm kinh nghiệm"
            content="[Tương thích với cầu mũ mới FlexGen™] Khác với mũ dòng S series bán chạy nhất của hãng, mũ DUO Safety bởi Bullard được phát triển trong những năm gần đây với cải tiến phù hợp với mọi người dùng ở các lĩnh vực khác nhau."
            bhldLink={bhldLink}
            tikiLink={tikiLink}
          />
          <Divider />
          <Stack direction="column" justifyContent="center">
            {/* Detail 1 */}
            <FirstDetail />
            {/* Detail 2 */}
            <SecondDetailList prods={prods} hatType="duo" />
            <AdBanner type={["glasses"]} />
            {/* Detail 3 */}
            <ThirdDetail />
            {/* Detail 4 */}
            <FourthDetailList prods={prods2} />
            {/* Detail 5 */}
            <FifthDetail />
            {/* Color */}
            <DUOColor />
          </Stack>
        </Layout>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default DUOContent;
