import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BlogMain from "../components/blogs/blog/BlogMain";
import Footer from "../components/footer/Footer";
import { doc, getDoc } from "firebase/firestore";
import { database } from "../firebase-lib/initFireBase";
import { Box, ThemeProvider, Typography } from "@mui/material";
import BoldText from "../components/layout/BoldText";
import Intro from "../components/landing-page/noti/Intro";
import LoadingCtx from "../contexts/LoadingCtx";
import CircularProgress from "@mui/material/CircularProgress";
import theme from "../styles/Styles";
import { Helmet } from "react-helmet";

const contentFooter = (
  <Typography variant="body1">
    Bullard là thương hiệu đến từ Mỹ chuyền về trang thiết bị an toàn lao động.
    Thiết kế của Bullard luôn hướng tới tính{" "}
    <BoldText> an toàn cao, bền bỉ, và hiện đại</BoldText>
  </Typography>
);

const ParticularBlog = () => {
  const params = useParams();
  const loadingCtx = useContext(LoadingCtx);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [date, setDate] = useState("");
  const [category, setCategory] = useState("");
  const [content, setContent] = useState("");
  const [img, setImg] = useState("");

  const blogId = params.blogId;
  const getBlog = async () => {
    const docRef = doc(database, "blogs", blogId);
    loadingCtx.setIsLoading(true);
    await getDoc(docRef, blogId).then((response) => {
      const myBlog = response.data();
      setTitle(myBlog.title);
      setSubtitle(myBlog.subtitle);
      setDate(myBlog.date);
      setCategory(myBlog.category);
      setContent(myBlog.content);
      setImg(myBlog.img);
    });
    loadingCtx.setIsLoading(false);
  };

  useEffect(() => {
    getBlog();
  }, [blogId]);
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Bullard Blog - {title}</title>
        <meta name="description" content={`${title} - ${subtitle}`} />
        <meta
          name="keywords"
          content={`Bullard blog Việt Nam, mũ bảo hộ, mũ bullard, ${title}, ${subtitle}`}
        />
        <meta name="robots" content="index, follow" />
        <meta name="revisit-after" content="2 days" />
        <meta name="MobileOptimized" content="width" />
        <meta name="HandheldFriendly" content="true" />
        <meta property="og:site_name" content={`Bullard Blog - ${title}`} />
        <meta property="og:type" content={`article:${title}`} />
        <meta property="og:title" content={`Bullard Blog - ${title}`} />
        <meta property="og:description" content={`${title} - ${subtitle}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Bullard Blog - ${title}`} />
        <meta property="og:image" content={img} />
        <meta property="og:image:url" content={img} />
        <meta property="og:image:secure_url" content={img} />
        <meta name="twitter:description" content={`${title} - ${subtitle}`} />
        <link rel="image_src" href={`${img}`} />
      </Helmet>
      <Intro pos="left" content={title} />
      {loadingCtx.isLoading === false ? (
        <BlogMain
          img={img}
          title={title}
          subtitle={subtitle}
          date={date}
          category={category}
          content={content}
        />
      ) : (
        <Box sx={{ textAlign: "center", mt: "50px", mb: "50px" }}>
          <CircularProgress />
        </Box>
      )}

      <Footer content={contentFooter} quote="" />
    </ThemeProvider>
  );
};

export default ParticularBlog;
