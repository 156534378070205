import { Box, Button, Typography } from "@mui/material";
import React from "react";
import {
  responsiveProductDetailContentFontSize,
  responsiveProductDetailTitleFontSize2,
} from "../../styles/Styles";

const ProdCard = (props) => {
  const { imgSrc, alt, title, subtitle, type, onDetailChangePC } = props;
  const prodCardContainerSX = {
    width: { xs: "100%", md: "calc(100%/3)" },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };
  const imgContainerSX = {
    display: "flex",
    width: "100%",
  };
  const contentContainerSX = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    mb: "20px",
  };
  const readmoreContainerSXPC = {
    width: "100%",
    marginTop: "auto",
    display: { xs: "none", md: "flex" },
    justifyContent: "center",
  };
  const typoTitleSX = {
    color: "#2D3748",
    fontWeight: 600,
    textAlign: "center",
  };
  const typoReadmoreSX = {
    fontWeight: 500,
    color: "#007053",
    textAlign: "center",
    "&:hover": {
      cursor: "pointer",
    },
  };
  return (
    <Box sx={prodCardContainerSX}>
      <Box sx={imgContainerSX}>
        <img
          src={imgSrc}
          alt={alt}
          style={{ height: "100%", width: "100%", objectFit: "contain" }}
        />
      </Box>
      <Box sx={contentContainerSX}>
        <Typography
          fontSize={responsiveProductDetailTitleFontSize2}
          variant="body1"
          sx={typoTitleSX}
        >
          {title}
        </Typography>
        <Typography
          fontSize={responsiveProductDetailContentFontSize}
          variant="body1"
          sx={{ color: "#2D3748", textAlign: "center", fontWeight: 400 }}
        >
          {subtitle}
        </Typography>
      </Box>
      <Box sx={readmoreContainerSXPC}>
        <Button>
          <Typography
            fontSize={responsiveProductDetailContentFontSize}
            variant="body1"
            sx={typoReadmoreSX}
            onClick={() => {
              onDetailChangePC(type);
            }}
          >
            ĐỌC THÊM
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default ProdCard;
