import React from "react";
import { Box, Grid, Paper } from "@mui/material";
import ImageProduct from "../../image/ImageProduct";
import TextOnCard from "./text-on-card/TextOnCard";
import { useNavigate } from "react-router-dom";

const ProductGrid = (props) => {
  const { unitProducts } = props;
  const navigate = useNavigate();
  return (
    <Grid container spacing={3}>
      {unitProducts.map((product) => (
        <Grid key={product.id + Math.random()} item xs={12} md={4} container>
          <Paper
            key={product.id + Math.random()}
            elevation={0}
            sx={{
              postion: "relative",
              backgroundColor: "#F4F4F4",
              pt: "24px",
              pb: "24px",
              width: "100%",
              justify: "center",
              borderRadius: 5,
              "&:hover": {
                cursor: "pointer",
              },
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 2,
                  pr: { xs: "40px", sm: "140px", md: "35px", lg: "40px" },
                  pl: { xs: "40px", sm: "140px", md: "35px", lg: "40px" },
                  width: "100%",
                }}
              >
                <Box
                  width="100%"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: { lg: "330px" },
                  }}
                >
                  <ImageProduct
                    image={product.image}
                    id={product.id}
                    alt={product.alt}
                    width="100%"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexShrink: 0,
                  flex: 1,
                  pl: "10px",
                  pr: "10px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  navigate(`/san-pham/${product.id}`);
                }}
              >
                <TextOnCard
                  title={product.title}
                  content={product.content}
                  id={product.id}
                  colorBelow="#F4F4F4"
                  colorAbove="#2D3748"
                />
              </Box>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductGrid;
