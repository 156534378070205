import { Box, Typography } from "@mui/material";
import React from "react";
import { responsiveProductDetailTitleFontSize } from "../../styles/Styles";
import ProdCardNoInter from "./ProdCardNoInter";
const FourthDetailList = (props) => {
  const { prods } = props;
  const detailContainerSX = {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    justifyContent: "center",
    gap: "50px",
  };
  return (
    <Box sx={{ mt: 8 }}>
      <Typography
        fontSize={responsiveProductDetailTitleFontSize}
        variant="h3"
        sx={{
          color: "#2D3748",
          textAlign: "center",
          mb: { xs: "18px", md: "25px" },
          fontWeight: "bold"
        }}
      >
        HỆ THỐNG DÂY QUAI
      </Typography>
      <Box sx={detailContainerSX}>
        {prods.map((prod) => {
          return (
            <ProdCardNoInter
              key={prod.alt + Math.random()}
              imgSrc={prod.imgSrc}
              alt={prod.alt}
              content={prod.content}
              hidden={prod.hidden}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default FourthDetailList;
