import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import BoldText from "../../layout/BoldText";
import {
  responsiveProductDetailTitleFontSize,
  responsiveProductDetailContentFontSize,
} from "../../../styles/Styles";

const FirstDetail = () => {
  return (
    <Grid
      container
      justifyContent="center"
      spacing={5}
      sx={{ alignItems: "center", mt: 2 }}
    >
      <Grid item xs={12} lg={6}>
        <img
          src="https://res.cloudinary.com/dtkdsevtm/image/upload/v1597918835/images/s51-red-face_hhsbca.jpg"
            alt="Mặt Trước của Mũ Bullard S51"
            width="100%"
          />
      </Grid>
      <Grid item xs={12} md={6}>
        <Box display="flex" justifyContent="center">
          <Stack direction="column" justifyContent="space-between" spacing={2}>
            <Typography
              fontSize={responsiveProductDetailTitleFontSize}
              variant="h3"
              sx={{ color: "#2D3748" , fontWeight: "bold"}}
            >
              THIẾT KẾ ĐẶC TRƯNG
            </Typography>
            <Typography
              fontSize={responsiveProductDetailContentFontSize}
              variant="body1"
              sx={{ color: "#646E73" }}
            >
              Là một trong những mũ bán chạy nhất tại thị trường Mỹ trong hơn
              120 năm lịch sử của hãng, mũ Bullard S51 sở hữu thiết kế kiểu Mỹ,
              khác biệt với các mũ khác trên thị trường Việt Nam.
            </Typography>
            <Typography
              fontSize={responsiveProductDetailContentFontSize}
              variant="body1"
              sx={{ color: "#646E73" }}
            >
              <BoldText>Chắc Chắn và Linh Hoạt:</BoldText> Ba đường gân trên mũ
              giúp làm tăng khả năng chịu lực cho mũ. Độ cứng ép ngang được nâng
              cao nhờ vành cắt nước mưa xung quanh mũ. Cùng với vành cắt nước
              mưa, lưỡi trai được thiết kệ hoàn hảo, giúp người dùng có tầm nhìn
              bao quát rộng nhưng đảm bảo che chắn an toàn cho toàn bộ khuân
              mặt.
            </Typography>
            <Typography
              fontSize={responsiveProductDetailContentFontSize}
              variant="body1"
              sx={{ color: "#646E73" }}
            >
              <BoldText>Vật Liệu Nhẹ và Bền Bỉ:</BoldText> Mũ Bullard S51 thuộc
              loại mũ nhẹ nhất cùng phần khúc khi sử dụng vật liệu Nhựa
              Polyethylene Mật Độ Cao (HDPE) giúp tăng độ bền lão hóa và sự dẻo
              dai của mũ trong thời gian dài.
            </Typography>
            <Typography
              fontSize={responsiveProductDetailContentFontSize}
              variant="body1"
              sx={{ color: "#646E73" }}
            >
              <BoldText>Phụ Kiện:</BoldText> Hai bên thành mũ là hai khe giúp
              người dùng có thể trang bị thêm phụ kiện bảo vệ kèm theo như bịt
              tai hay mặt kính bảo vệ. Xin liên hệ đại lý Bullard để biết thêm
              chi tiết.
            </Typography>
            <Typography
              fontSize={responsiveProductDetailContentFontSize}
              variant="body1"
              sx={{ color: "#646E73" }}
            >
              <BoldText>Nhận Diện Công Ty:</BoldText> Mũ Bullard S51 sở hữu một
              mặt phẳng bản to trước mũ giúp người dùng thể hiện đầy đủ logo
              trên đó.
            </Typography>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FirstDetail;
