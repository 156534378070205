import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  responsiveTitleFontSize,
  responsiveSubtitleFontSize,
} from "../styles/Styles";
import { auth } from "../firebase-lib/initFireBase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../styles/Styles";
import { useNavigate } from "react-router-dom";
import ContentLayout from "../components/layout/ContentLayout";
import AdminAuthContext from "../contexts/AdminAuthContext";

const LoginAdmin = () => {
  const navigate = useNavigate();
  const login = useContext(AdminAuthContext);
  const [loginAccount, setLoginAccount] = useState("");
  const [loginPassword, setLoginPassWord] = useState("");

  useEffect(() => {
    if (login.logStatus === "success") {
      navigate("/dashboard/admin");
    } else if (login.token !== null) {
      navigate("/dashboard/admin");
    }
  }, [login.logStatus, navigate]);

  const buttonSX = {
    "&.MuiButtonBase-root:hover": {
      background: "none",
    },
  };

  useEffect(() => {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const onChangeAccountHandler = (event) => {
    setLoginAccount(event.target.value);
  };
  const onChangePasswordHandler = (event) => {
    setLoginPassWord(event.target.value);
  };
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      let userId;
      await signInWithEmailAndPassword(auth, loginAccount, loginPassword).then(
        async (userCredential) => {
          // console.log("Hello", userCredential.user.getIdToken());
          userId = await userCredential.user.getIdToken().then((token) => {
            return token;
          });
        }
      );
      login.setLoggin(userId);
      setLoginAccount("");
      setLoginPassWord("");
      navigate("/dashboard/admin", { replace: true });
    } catch (error) {
      console.log("Wrong infomation!");
      console.log(error.message);
      setLoginAccount("");
      setLoginPassWord("");
      navigate("/nimda", { replace: true });
    }
  };
  return (
    <ContentLayout>
      <ThemeProvider theme={theme}>
        <form onSubmit={onSubmitHandler}>
          <Card>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                  <Typography
                    fontSize={responsiveTitleFontSize}
                    variant="h3"
                    color="primary"
                  >
                    Login
                  </Typography>
                </Grid>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                  <TextField
                    label="Username"
                    placeholder="Enter username"
                    fullWidth
                    onChange={onChangeAccountHandler}
                    value={loginAccount}
                  ></TextField>
                </Grid>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                  <TextField
                    label="Password"
                    placeholder="Enter password"
                    fullWidth
                    onChange={onChangePasswordHandler}
                    value={loginPassword}
                  ></TextField>
                </Grid>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                  <Stack direction="row" justifyContent="space-between">
                    <Button type="submit" sx={buttonSX}>
                      <Typography
                        fontSize={responsiveSubtitleFontSize}
                        variant="h4"
                      >
                        Login
                      </Typography>
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      </ThemeProvider>
    </ContentLayout>
  );
};

export default LoginAdmin;
