import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ImageProduct from "../landing-page/image/ImageProduct";
import TextOnCard from "../landing-page/product-list/product-grid/text-on-card/TextOnCard";
import MuS51 from "../image/muvang.png";
import MuDUO from "../image/muxanh.png";
import Layout from "../layout/Layout";
import { responsivePageTitleFontSize } from "../../styles/Styles";
import { useNavigate } from "react-router-dom";


const HatList = () => {
  const navigate = useNavigate();
  const unitProducts = [
    {
      id: "mu-bao-ho-bullard-s51",
      title: "Mũ Bảo Hộ S51",
      content: "Chuyên dụng cho ngành điện lực.",
      image: `${MuS51}`,
      alt: "Mũ Bảo Hộ S51",
    },
    {
      id: "mu-bao-ho-bullard-duo-safety-c422",
      title: "Mũ Bảo Hộ DUO SAFETY C422",
      content: "Đa dạng mọi lĩnh vực.",
      image: `${MuDUO}`,
      alt: "Mũ Bảo Hộ Duo Safety C422",
    },
  ];
  useEffect(() => {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Layout>
      <Box sx={{ textAlign: "center", mb: "30px", mt: "30px" }}>
        <Typography
          fontSize={responsivePageTitleFontSize}
          variant="h1"
          sx={{ color: "#2D3748" }}
        >
          MŨ (NÓN) BẢO HỘ TƯƠNG THÍCH CẦU MŨ FLEXGEN™
        </Typography>
      </Box>
      <Grid container spacing={3} sx={{ mb: "30px", justifyContent: "center" }}>
        {unitProducts.map((product) => (
          <Grid key={product.id + Math.random()} item xs={12} md={4} container>
            <Paper
              key={product.id + Math.random()}
              elevation={0}
              sx={{
                postion: "relative",
                backgroundColor: "#F4F4F4",
                pt: "24px",
                pb: "24px",
                width: "100%",
                justify: "center",
                borderRadius: 5,
                "&:hover": {
                  cursor: "pointer",
                },
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                  height: "100%",
                }}
              >
                <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 2,
                  pr: { xs: "40px", sm: "140px", md: "35px", lg: "40px" },
                  pl: { xs: "40px", sm: "140px", md: "35px", lg: "40px" },
                  width: "100%",
                }}
              >
                <Box
                  width="100%"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: { lg: "330px" },
                  }}
                >
                  <ImageProduct
                    image={product.image}
                    id={product.id}
                    alt={product.alt}
                    width="100%"
                  />
                </Box>
              </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    flexShrink: 0,
                    flex: 1,
                    pl: "10px",
                    pr: "10px",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    navigate(`/san-pham/${product.id}`);
                  }}
                >
                  <TextOnCard
                    title={product.title}
                    content={product.content}
                    id={product.id}
                    colorBelow="#F4F4F4"
                    colorAbove="#2D3748"
                  />
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
};

export default HatList;
