import { Box } from "@mui/material";
import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import DetailType1 from "../DUO-SAFETY/detail-grid/DetailType1";
import DetailType2 from "../DUO-SAFETY/detail-grid/DetailType2";
import DetailType3 from "../DUO-SAFETY/detail-grid/DetailType3";
import { Expo, gsap } from "gsap";
import { useEffect } from "react";
import S51DetailType1 from "../S51/detail-grid/S51DetailType1";
import S51DetailType2 from "../S51/detail-grid/S51DetailType2";
import S51DetailType3 from "../S51/detail-grid/S51DetailType3";
const AccordionProd = (props) => {
  const { hatType, type, change, accoClass, onCloseAccordion } = props;
  useEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo(
      `.accordion-${accoClass}`,
      {
        opacity: 0,
        y: 100,
        height: 0,
      },
      { opacity: 1, height: "auto", y: 0, ease: Expo.easeOut, duration: 1 }
    );
  }, [change, accoClass]);
  let contentType;
  if (hatType === "duo") {
    if (type === "1") {
      contentType = <DetailType1 />;
    } else if (type === "2") {
      contentType = <DetailType2 />;
    } else if (type === "3") {
      contentType = <DetailType3 />;
    }
  } else if (hatType === "s51") {
    if (type === "1") {
      contentType = <S51DetailType1 />;
    } else if (type === "2") {
      contentType = <S51DetailType2 />;
    } else if (type === "3") {
      contentType = <S51DetailType3 />;
    }
  }
  const accordionSX = {
    position: "relative",
    mt: "25px",
    width: "100%",
    border: "3px solid #007053",
    padding: "25px",
    height: "auto",
    overflowY: "hidden",
  };
  const clearIconSX = {
    position: "absolute",
    zIndex: 100,
    top: { xs: "2%", md: "8%" },
    right: { xs: "4%", md: "2%" },
    color: "#2D3748",
    cursor: "pointer",
  };
  return (
    <Box className={`accordion-${accoClass}`} sx={accordionSX}>
      <Box
        sx={clearIconSX}
        onClick={() => {
          onCloseAccordion(type);
        }}
      >
        <ClearIcon fontSize="medium" />
      </Box>
      {contentType}
    </Box>
  );
};

export default AccordionProd;
