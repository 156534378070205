import { useRef, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import {
  responsiveProductDetailTitleFontSize,
  responsiveProductDetailContentFontSize,
  responsiveColorFontSize,
} from "../../styles/Styles";
import Slider from "react-slick";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const images = {
  0: {
    id: "Xanh Kentucky",
    src: "https://res.cloudinary.com/dtkdsevtm/image/upload/v1597915399/images/duo-safety-xanh-blue-kentucky_o8z6ah.jpg",
  },
  1: {
    id: "Vàng",
    src: "https://res.cloudinary.com/dtkdsevtm/image/upload/v1597915399/images/duo-safety-vang-yellow_msutz2.jpg",
  },
  2: {
    id: "Trắng",
    src: "https://res.cloudinary.com/dtkdsevtm/image/upload/v1597915399/images/duo-safety-trang-white_pgmd0c.jpg",
  },
};

const DUOColor = () => {
  const [borderColorBlue, setBorderColorBlue] = useState("#007053");
  const [borderColorYellow, setBorderColorYellow] = useState("#bbc1c4");
  const [borderColorWhite, setBorderColorWhite] = useState("#bbc1c4");
  const slider = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    className: "center",
    dots: true,
    centerMode: true,
    focusOnSelect: false,
    arrows: false,
    infinite: true,
    speed: 500,
    centerPadding: "400px",
    swipeToSlide: true,
    slidesToShow: 1,
    cssEase: "ease-out",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "280px",
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "240px",
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "190px",
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "140px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "30px",
        },
      },
    ],
    afterChange: (currentSlide) => {
      setCurrentSlide(currentSlide);
      for (let i = 0; i < Object.keys(images).length; i++) {
        if (currentSlide === i) {
          if (images[i]["id"] === "Xanh Kentucky") {
            onBlueClickHandler();
          }
          if (images[i]["id"] === "Vàng") {
            onYellowClickHandler();
          }
          if (images[i]["id"] === "Trắng") {
            onWhiteClickHandler();
          }
        }
      }
      // console.log("current slide:", currentSlide);
    },
  };

  const onNextHandler = () => {
    slider?.current?.slickNext();
  };
  const onPrevHandler = () => {
    slider?.current?.slickPrev();
  };
  const colorBoxSX = {
    mt: 3.5,
    mb: 3.5,
    display: "flex",
    flexDirection: "row",
    gap: { xs: "20px", lg: "30px" },
    justifyContent: "center",
  };
  const onBlueClickHandler = () => {
    setBorderColorBlue("#007053");
    if (borderColorYellow === "#007053") {
      setBorderColorYellow("#bbc1c4");
    }
    if (borderColorWhite === "#007053") {
      setBorderColorWhite("#bbc1c4");
    }
    slider?.current?.slickGoTo(0);
  };
  const onYellowClickHandler = () => {
    setBorderColorYellow("#007053");
    if (borderColorBlue === "#007053") {
      setBorderColorBlue("#bbc1c4");
    }
    if (borderColorWhite === "#007053") {
      setBorderColorWhite("#bbc1c4");
    }
    slider?.current?.slickGoTo(1);
  };
  const onWhiteClickHandler = () => {
    setBorderColorWhite("#007053");
    if (borderColorBlue === "#007053") {
      setBorderColorBlue("#bbc1c4");
    }
    if (borderColorYellow === "#007053") {
      setBorderColorYellow("#bbc1c4");
    }
    slider?.current?.slickGoTo(2);
  };
  return (
    <Grid
      container
      justifyContent="center"
      spacing={5}
      sx={{ alignItems: "center", mt: 8, mb: 8 }}
    >
      <Grid item xs={12} md={12}>
        <Typography
          fontSize={responsiveProductDetailTitleFontSize}
          variant="h3"
          sx={{ color: "#2D3748", textAlign: "center" , fontWeight: "bold"}}
        >
          MÀU SẮC
        </Typography>
        <Typography
          fontSize={responsiveProductDetailContentFontSize}
          variant="body1"
          sx={{
            color: "#646E73",
            textAlign: "center",
            mt: 0.5,
          }}
        >
          Bullard tại Việt Nam xin hân hạnh giới thiệu ba màu của dòng Mũ DUO
          Safety bởi Bullard:
        </Typography>
        <Box sx={colorBoxSX}>
          <Typography
            fontSize={responsiveColorFontSize}
            variant="body1"
            sx={{
              color: "#646E73",
              fontWeight: "bold",
              textAlign: "center",
              pl: "10px",
              pr: "10px",
              border: `2px solid ${borderColorBlue}`,
              "&:hover": {
                cursor: "pointer",
              },
              borderRadius: "5px",
            }}
            onClick={onBlueClickHandler}
          >
            Xanh Kentucky
          </Typography>
          <Typography
            fontSize={responsiveColorFontSize}
            variant="body1"
            sx={{
              color: "#646E73",
              fontWeight: "bold",
              textAlign: "center",
              pl: "10px",
              pr: "10px",
              border: `2px solid ${borderColorYellow}`,
              "&:hover": {
                cursor: "pointer",
              },
              borderRadius: "5px",
            }}
            onClick={onYellowClickHandler}
          >
            Vàng
          </Typography>
          <Typography
            fontSize={responsiveColorFontSize}
            variant="body1"
            sx={{
              color: "#646E73",
              fontWeight: "bold",
              textAlign: "center",
              pl: "10px",
              pr: "10px",
              border: `2px solid ${borderColorWhite}`,
              "&:hover": {
                cursor: "pointer",
              },
              borderRadius: "5px",
            }}
            onClick={onWhiteClickHandler}
          >
            Trắng
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box
            flexGrow={1}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Button onClick={onPrevHandler}>
              <ArrowBackIosNewIcon fontSize="large" />
            </Button>
          </Box>
          <Box
            flexGrow={1}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button onClick={onNextHandler}>
              <ArrowForwardIosIcon fontSize="large" />
            </Button>
          </Box>
        </Box>
        <Slider ref={slider} {...settings}>
          <Box
            sx={{
              pl: { xs: "5px", md: "50px" },
              pr: { xs: "5px", md: "50px" },
            }}
          >
            <img
              src="https://res.cloudinary.com/dtkdsevtm/image/upload/v1597915399/images/duo-safety-xanh-blue-kentucky_o8z6ah.jpg"
              alt="Bullard DUO SAFETY Xanh Kentucky"
              width="100%"
            />
          </Box>
          <Box
            sx={{
              pl: { xs: "5px", md: "50px" },
              pr: { xs: "5px", md: "50px" },
            }}
          >
            <img
              src="https://res.cloudinary.com/dtkdsevtm/image/upload/v1597915399/images/duo-safety-vang-yellow_msutz2.jpg"
              alt="Bullard DUO SAFETY Vàng"
              width="100%"
            />
          </Box>
          <Box
            sx={{
              pl: { xs: "5px", md: "50px" },
              pr: { xs: "5px", md: "50px" },
            }}
          >
            <img
              src="https://res.cloudinary.com/dtkdsevtm/image/upload/v1597915399/images/duo-safety-trang-white_pgmd0c.jpg"
              alt="Bullard DUO SAFETY Trắng"
              width="100%"
            />
          </Box>
        </Slider>
      </Grid>
    </Grid>
  );
};

export default DUOColor;
