import React from "react";
import { Box, Stack, ThemeProvider, Typography } from "@mui/material";
import theme, {
  responsiveTitleFontSize,
  responsiveSubtitleFontSize,
  responsiveDateBlogFontSize,
  responsiveCateBlogFontSize,
} from "../../../../styles/Styles";

const ContentHeader = (props) => {
  const { title, subtitle, date, category } = props;
  return (
    <ThemeProvider theme={theme}>
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography
            fontSize={responsiveCateBlogFontSize}
            variant="body1"
            color="primary"
            sx={{ fontWeight: "bold" }}
          >
            {category.toUpperCase()}
          </Typography>
          <Typography
            fontSize={responsiveDateBlogFontSize}
            variant="body1"
            sx={{ fontWeight: "300" }}
          >
            {date}
          </Typography>
        </Box>
        <Typography
          fontSize={responsiveTitleFontSize}
          variant="h1"
          sx={{ fontWeight: "800", color: "#2D3748" }}
        >
          {title}
        </Typography>
        <Typography
          fontSize={responsiveSubtitleFontSize}
          variant="body1"
          sx={{ color: "#4D4D4D" }}
        >
          {subtitle}
        </Typography>
      </Stack>
    </ThemeProvider>
  );
};

export default ContentHeader;
