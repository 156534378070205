import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
const HorizontalScroll = (props) => {
  const sliderRef = useRef(null);
  const { items, height, padding } = props;
  useEffect(() => {
    const slider = sliderRef.current;
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
      console.log("on mouse down:", scrollLeft);
      slider.style.cursor = "grabbing";
    });
    slider.addEventListener("mouseenter", () => {
      slider.style.cursor = "grab";
    });
    slider.addEventListener("mouseleave", () => {
      isDown = false;
    });
    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.style.cursor = "grab";
    });
    slider.addEventListener("pointermove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-speed
      slider.scrollLeft = scrollLeft - walk;
      console.log("on mouse move:", slider.scrollLeft);
      // console.log(walk);
    });
  }, []);
  return (
    <>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          ref={sliderRef}
          id="slider-inner"
          sx={{
            position: "relative",
            width: "100%",
            height: { height },
            overflowX: "scroll",
            overflowY: "hidden",
            whiteSpace: "nowrap",
            scrollBehavior: "smooth",
          }}
        >
          {items.map((item) => (
            <img
              key={item.id}
              style={{
                position: "absolute",
                height: "100%",
                display: "inline-block",
                padding: { padding },
              }}
              src={item.src}
              alt={item.alt}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default HorizontalScroll;
