import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  responsiveLinkFontSize,
  responsiveSubtitleOnBannerFontSize,
  responsiveTitleOnBannerFontSize,
} from "../../styles/Styles";
import Muvang from "../image/muvang.png";
import Muxanh from "../image/muxanh.png";

const typeDict = {
  "hard-hat": {
    s51: {
      url: "/san-pham/mu-bao-ho-bullard-s51",
      title: "MŨ BẢO HỘ S51",
      subtitle: "Chuyên dụng cho ngành điện lực.",
      src: `${Muvang}`,
    },
    duo: {
      url: "/san-pham/mu-bao-ho-bullard-duo-safety-c422",
      title: "MŨ BẢO HỘ DUO SAFETY C422",
      subtitle: "Đa dạng mọi lĩnh vực",
      src: `${Muxanh}`,
    },
  },
  glasses: {
    se2: {
      url: "/san-pham/kinh-bao-ho-bullard-se2",
      title: "KÍNH BẢO HỘ SE2",
      subtitle: "Thoải mái. An toàn. Chống đọng sương.",
      src: `https://res.cloudinary.com/dtkdsevtm/image/upload/v1605201286/images/se2-main-mobile_fq6rax.jpg`,
    },
  },
};

const getRandomProduct = (obj) => {
  const keys = Object.keys(obj);
  return keys[Math.floor(Math.random() * keys.length)];
};

const AdBanner = (props) => {
  const navigate = useNavigate();
  let { type } = props;
  let title;
  let subtitle;
  let src;
  let url;
  const typeKeys = Object.keys(typeDict);
  if (Array.isArray(type) === true) {
    for (let i = 0; i < type.length; i++) {
      if (typeKeys.includes(type[i]) === false) {
        console.log(`${type[i]} is not the type! Check again.`);
        return false;
      }
    }
    let mainType = type[Math.floor(Math.random() * (type.length - 1))];
    let innerMainType = getRandomProduct(typeDict[`${mainType}`]);
    title = typeDict[`${mainType}`][`${innerMainType}`].title;
    subtitle = typeDict[`${mainType}`][`${innerMainType}`].subtitle;
    src = typeDict[`${mainType}`][`${innerMainType}`].src;
    url = typeDict[`${mainType}`][`${innerMainType}`].url;
  } else {
    if (typeKeys.includes(type) === false) {
      console.log(`${type} is not the type! Check again.`);
      return false;
    }
    let mainType = type;
    let innerMainType = getRandomProduct(typeDict[`${mainType}`]);
    title = typeDict[`${mainType}`][`${innerMainType}`].title;
    subtitle = typeDict[`${mainType}`][`${innerMainType}`].subtitle;
    src = typeDict[`${mainType}`][`${innerMainType}`].src;
    url = typeDict[`${mainType}`][`${innerMainType}`].url;
  }

  const containerSX = {
    marginTop: 3,
    width: "100%",
    height: { xs: "220px", sm: "270px", lg: "300px" },
  };
  const innerContainerSX = {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    paddingTop: "20px",
    paddingBottom: "20px",
    backgroundColor: "#f4f4f4",
    borderRadius: "20px",
    border: "1px solid #f4f4f4",
  };
  const contentSX = {
    width: { xs: "45%" },
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    justifyContent: "center",
    textAlign: "center",
  };
  const imageSX = {
    width: { xs: "55%" },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
  };
  const buttonSX = {
    backgroundColor: "#007053",
    "&.MuiButtonBase-root:hover": {
      background: "#00e6a8",
    },
    borderRadius: 5,
    paddingTop: 1.5,
    paddingBottom: 1.5,
    paddingLeft: 3,
    paddingRight: 3,
  };
  const typoTitleSX = { color: "#2D3748", fontWeight: 700 };
  const typoSubtitleSX = { color: "#2D3748" };
  const typoButtonSX = { fontWeight: "bold", color: "#FFFFFF" };
  const onClickHandler = () => {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
    navigate(`${url}`);
  };
  return (
    <Box sx={containerSX}>
      {/* <Divider /> */}
      <Box sx={innerContainerSX}>
        <Box sx={contentSX}>
          <Typography
            fontSize={responsiveTitleOnBannerFontSize}
            variant="h1"
            sx={typoTitleSX}
          >
            {title}
          </Typography>
          <Typography
            fontSize={responsiveSubtitleOnBannerFontSize}
            variant="h2"
            sx={typoSubtitleSX}
          >
            {subtitle}
          </Typography>
          <a
            style={{
              textDecoration: "none",
              color: "#2d3748",
            }}
            href="tel:0903422353"
          >
            <Button sx={buttonSX}>
              <Typography
                fontSize={responsiveLinkFontSize}
                variant="body1"
                sx={typoButtonSX}
              >
                MUA NGAY
              </Typography>
            </Button>
          </a>
        </Box>
        <Box sx={imageSX} onClick={onClickHandler}>
          <img
            src={src}
            style={{ objectFit: "contain" }}
            height="100%"
            width="100%"
            alt={title}
          />
        </Box>
      </Box>
      {/* <Divider /> */}
    </Box>
  );
};

export default AdBanner;
