import {
  Box,
  Grid,
  Typography,
  styled,
  Divider,
  Modal,
  Button,
} from "@mui/material";
import React from "react";
import ImageProduct from "../landing-page/image/ImageProduct";
import ShopLink from "../S51/ShopLink";
import {
  responsiveTitleFontSize,
  responsiveContentFontSize,
} from "../../styles/Styles";
import Vimeo from "@u-wave/react-vimeo";
import { useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const Header = styled(Box)({
  width: "100%",
  paddingBottom: "30px",
});

const modalSX = {
  width: { xs: "100%", md: "960px" },
  height: { xs: "55%", md: "600px" },
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const buttonModalSX = {
  position: "relative",
  float: "right",
  backgroundColor: "transparent",
  "&.MuiButtonBase-root:hover": {
    background: "none",
  },
};
const buttonSX = {
  backgroundColor: "#007053",
  "&.MuiButtonBase-root:hover": {
    background: "#00e6a8",
  },
  borderRadius: 5,
  paddingTop: 1,
  paddingBottom: 1,
  paddingLeft: 2,
  paddingRight: 2,
  mt: "15px",
  mb: "15px",
};

const RepresentProduct = (props) => {
  const { bhldLink, tikiLink, name, content, imageSrc, alt, title } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const onErrorHandler = () => {
    alert("Lỗi xảy ra! Vui lòng tải lại trang.");
  };
  return (
    <Header>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalSX}>
          <Button sx={buttonModalSX} onClick={handleClose}>
            <Typography
              id="modal-modal-title"
              variant="body1"
              sx={{ color: "#FFFFFF", fontWeight: "bold" }}
              fontSize={responsiveContentFontSize}
            >
              ĐÓNG
            </Typography>
          </Button>
          <Vimeo
            video="771622962"
            width="100%"
            height="100%"
            responsive={true}
            style={{ position: "relative", top: "8%" }}
            onError={onErrorHandler}
            autoplay
          />
        </Box>
      </Modal>
      <Grid
        container
        sx={{
          borderRadius: 5,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              pl: { xs: "0px", sm: "50px", md: "40px", lg: "80px" },
              pr: { xs: "0px", sm: "50px", md: "40px", lg: "120px" },
              margin: "auto",
              pt: { xs: "30px", sm: "15px", md: "20px", lg: "60px" },
              pb: { xs: "0px", sm: "0px", md: "0px", lg: "60px" },
            }}
          >
            <Box
              sx={{
                margin: "auto",
                width: "100%",
                display: { xs: "none", md: "flex" },
                alignItems: "center",
              }}
            >
              <Box sx={{ width: { lg: "600px", md: "100%" } }}>
                <ImageProduct
                  image={imageSrc}
                  id={name}
                  alt={alt}
                  width={"100%"}
                />
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={5}
            sx={{
              textAlign: "left",
              margin: "auto",
              mb: { xs: "0px", md: "auto" },
              pt: { sm: "30px" },
              alignItems: "center",
              justifyContent: "center",
              pr: { xs: "0px", lg: "80px" },
            }}
          >
            <Typography
              fontSize={responsiveTitleFontSize}
              variant="h1"
              sx={{
                fontWeight: "800",
                color: "#2D3748",
                textAlign: { xs: "center", md: "left" },
              }}
            >
              {title}
            </Typography>
            {title.includes("MŨ") === true ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "left" },
                }}
              >
                <Button
                  sx={buttonSX}
                  onClick={() => {
                    handleOpen();
                  }}
                >
                  <Typography
                    fontSize={responsiveContentFontSize}
                    variant="body1"
                    sx={{
                      color: "#FFFFFF",
                      textAlign: { xs: "center", md: "left" },
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <PlayArrowIcon fontSize="medium" />
                    <span style={{ margin: 0 }}>XEM VIDEO</span>
                  </Typography>
                </Button>
              </Box>
            ) : (
              <></>
            )}
            <Typography
              fontSize={responsiveContentFontSize}
              variant="body1"
              sx={{
                color: "#2D3748",
                textAlign: { xs: "center", md: "left" },
                mb: "35px",
              }}
            >
              {content}
            </Typography>

            <Box
              sx={{
                margin: "auto",
                width: "100%",
                display: { xs: "flex", md: "none" },
                alignItems: "center",
                mb: "35px",
              }}
            >
              <Box
                sx={{ margin: "0 auto", width: { xs: "100%", sm: "400px" } }}
              >
                <ImageProduct
                  image={imageSrc}
                  id={name}
                  alt={alt}
                  width="100%"
                />
              </Box>
            </Box>

            <Divider sx={{ borderBottomWidth: "1px", mb: "35px" }}></Divider>
            <Typography
              fontSize={responsiveContentFontSize}
              variant="body1"
              sx={{ fontWeight: "bold", color: "#2D3748", mb: 1 }}
            >
              Mua tại
            </Typography>
            <Box display="block">
              <ShopLink bhldLink={bhldLink} tikiLink={tikiLink} id={name} />
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Header>
  );
};

export default RepresentProduct;
