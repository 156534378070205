import React from "react";
import { Typography } from "@mui/material";
import { responsiveTitleOnCardFontSize } from "../../../../../styles/Styles";

const Title = (props) => {
  const { title } = props;
  return (
    <Typography
      fontSize={responsiveTitleOnCardFontSize}
      variant="h3"
      sx={{ fontWeight: "bold", color: "#2D3748", textAlign: {xs: 'center'} }}
    >
      {title}
    </Typography>
  );
};

export default Title;
