import React from "react";
import { useNavigate } from "react-router-dom";

const ImageProduct = (props) => {
  const navigate = useNavigate();
  const { image, id, alt, width } = props;
  return (
    <img
      src={image}
      alt={alt}
      width={width}
      // maxheight="100%"
      style={{ objectFit: "contain" }}
      onClick={() => {
        navigate(`/san-pham/${id}`);
      }}
    />
  );
};

export default ImageProduct;
