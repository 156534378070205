import { Container } from "@mui/material";
import React from "react";

const ContentLayout = (props) => {
  return (
    <Container sx={{ backgroundColor: "none" }} maxWidth="lg">
      {props.children}
    </Container>
  );
};

export default ContentLayout;
