import React, { useEffect } from "react";
import Layout from "../../layout/Layout";
import { Box, Grid, styled } from "@mui/material";
import BlogHeader from "./BlogHeader";
import BlogFooter from "./BlogFooter";
import BlogContent from "./blog-content/BlogContent";
import ContentLayout from "../../layout/ContentLayout";

const BlogMain = (props) => {
  const { img, title, subtitle, date, category, content } = props;

  const BlogHeaderContainer = styled(Box)({
    textAlign: "center",
  });
  const BlogContentContainer = styled(Box)({marginTop:"10px"});
  const BlogFooterContainer = styled(Box)({});
  useEffect(() => {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <React.Fragment>
      <BlogHeaderContainer>
        <BlogHeader media={img} />
      </BlogHeaderContainer>
      <Layout>
        <ContentLayout>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <BlogContentContainer>
                <BlogContent
                  title={title}
                  subtitle={subtitle}
                  date={date}
                  category={category}
                  content={content}
                />
              </BlogContentContainer>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </ContentLayout>
        <BlogFooterContainer>
          <BlogFooter />
        </BlogFooterContainer>
      </Layout>
    </React.Fragment>
  );
};

export default BlogMain;
