import { Box } from "@mui/material";
import React from "react";
import Feature from "./feature/text/Feature";
import Noti from "./noti/Noti";
import ProductList from "./product-list/ProductList";
import Layout from "../layout/Layout";
import flexgen from "../image/flexgen.png";
import MuS51 from "../image/muvang.png";
import MuDUO from "../image/muxanh.png";
import KinhSE2 from "../image/kinhse2.png";

const LandingPage = () => {
  const intro = "THƯƠNG HIỆU ĐẾN TỪ MỸ VỚI HƠN 120 NĂM KINH NGHIỆM.";
  const featureText = {
    id: "blog/sML0ByX2zsX2cEFvh3OV/flexgen™---cầu-mũ-núm-vặn-công-thái-học",
    equivalent: "flexgen",
    header: "Phụ kiện mới.",
    title: "Nhẹ. Chắc chắn. Nâng cấp thôi!",
    content:
      "Núm vặn FlexGen™ mới do Bullard thiết kế giúp dễ dàng thao tác hơn so với các thế hệ trước, tạo sự thoải mái và tiện lợi cho người dùng.",
  };
  const image = { src: `${flexgen}`, id: "flexgen", alt: "flexgen" };
  const unitProducts = [
    {
      id: "mu-bao-ho-bullard-s51",
      title: "Mũ Bảo Hộ S51",
      content: "Chuyên dụng cho ngành điện lực.",
      image: `${MuS51}`,
      alt: "Mũ Bảo Hộ S51",
    },
    {
      id: "mu-bao-ho-bullard-duo-safety-c422",
      title: "Mũ Bảo Hộ DUO SAFETY C422",
      content: "Đa dạng mọi lĩnh vực.",
      image: `${MuDUO}`,
      alt: "Mũ Bảo Hộ Duo Safety C422",
    },
    {
      id: "kinh-bao-ho-bullard-se2",
      title: "Kính Bảo Hộ SE2",
      content: "Thoải mái. An toàn. Chống đọng sương.",
      image: `${KinhSE2}`,
      alt: "Kính bảo hộ SE2 từ Mỹ | Chông xước, Chống Đọng Nước và Chống Va Đập",
    },
  ];
  return (
    <Box>
      <Noti content={intro} pos="center" />
      <Layout>
        <Feature featureText={featureText} image={image} />
        <ProductList
          unitProducts={unitProducts}
        />
      </Layout>
    </Box>
  );
};

export default LandingPage;
