import React from "react";
import { Typography } from "@mui/material";
import { responsiveBigTitleFontSize } from "../../../../styles/Styles";

const Header = () => {
  return (
    <Typography
      fontSize={responsiveBigTitleFontSize}
      variant="h1"
      sx={{
        textAlign: "center",
        fontWeight: "bold",
        color: "#2D3748",
        marginBottom: "20px",
      }}
    >
      Xem ngay những sản phẩm của Bullard
    </Typography>
  );
};

export default Header;
