import { Box } from "@mui/material";
import FooterMain from "./FooterMain";
import FooterSubMain from "./FooterSubMain";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../styles/Styles";

const Footer = (props) => {
  const {quote, content} = props;
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <FooterMain content = {content} quote = {quote} />
        <FooterSubMain />
      </Box>
    </ThemeProvider>
  );
};

export default Footer;
