import React from "react";
import { Typography } from "@mui/material";
import { responsiveDateBlogFontSize } from "../../../styles/Styles";

const CardDate = (props) => {
  const { date } = props;
  return (
    <Typography
      fontSize={responsiveDateBlogFontSize}
      variant="body1"
      sx={{ fontWeight: "300", color: "#2D3748" }}
    >
      {date}
    </Typography>
  );
};

export default CardDate;
