import { Box } from "@mui/material";
import React from "react";
import { useState } from "react";
import AccordionProd from "./AccordionProd";
import ProdCard from "./ProdCard";
import ProdCard2 from "./ProdCard2";

const DropdownProduct = (props) => {
  const { prods, hatType } = props;
  const [prodType, setProdType] = useState();
  const [accoChange, setAccoChange] = useState(false);
  const [isFirstPC, setIsFirstPC] = useState(false);
  const [currentTypePC, setCurrentTypePC] = useState(null);
  const dropDownContainerSX = {
    mt: "45px",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };
  const mainContentSX = {
    padding: { xs: "15px", md: "0px" },
    width: "100%",
    height: "90%",
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: { xs: "15px", md: "25px" },
  };
  const detailSX = {
    width: "100%",
  };
  const onPCSX = { width: "100%", display: { xs: "none", md: "block" } };
  const onMBSX = { width: "100%", display: { xs: "block", md: "none" } };

  const closeAccordionHandler = () => {
    setIsFirstPC(false);
  };
  const detailChangeHandlerPC = (type) => {
    if (isFirstPC === true) {
      if (currentTypePC !== type) {
        setCurrentTypePC(type);
        setProdType(type);
        setAccoChange((prev) => {
          return !prev;
        });
      }
    } else {
      setIsFirstPC(true);
      setCurrentTypePC(type);
      setProdType(type);
    }
    const detailPC = document.getElementById(`detail-onpc`);
    setTimeout(() => {
      detailPC.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  return (
    <Box sx={dropDownContainerSX}>
      <Box id="detail-onpc" sx={onPCSX}>
        <Box sx={mainContentSX}>
          {prods.map((prod) => {
            return (
              <ProdCard
                key={prod.title + Math.random()}
                imgSrc={prod.imgSrc}
                alt={prod.alt}
                title={prod.title}
                subtitle={prod.subtitle}
                type={prod.type}
                onDetailChangePC={detailChangeHandlerPC}
              />
            );
          })}
        </Box>
        {isFirstPC && (
          <Box sx={detailSX}>
            <AccordionProd
              hatType={hatType}
              accoClass="main"
              type={prodType}
              change={accoChange}
              onCloseAccordion={closeAccordionHandler}
            />
          </Box>
        )}
      </Box>
      <Box sx={onMBSX}>
        <Box sx={mainContentSX}>
          <ProdCard2 hatType={hatType} prods={prods} />
        </Box>
      </Box>
    </Box>
  );
};

export default DropdownProduct;
