import { createContext, useState } from "react";
import React from "react";
import { getAuth } from "firebase/auth";
import { useEffect } from "react";
const AdminAuthContext = createContext({
  token: "",
  logStatus: "loading",
  setLoggin: () => {},
  setLogout: () => {},
});
export const AdminAuthContextProvider = (props) => {
  const localToken = localStorage.getItem("user-bl-vn");
  const [logged, setLogged] = useState("loading");
  const auth = getAuth();
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser === null) {
        setLogged("empty-user");
      } else {
        currentUser
          .getIdToken()
          .then((token) => {
            if (localToken === null) {
              setLogged("empty-user");
            } else {
              setLogged(
                localToken === JSON.stringify(token) ? "success" : "error"
              );
            }
          })
          .catch(() => {
            setLogged("error");
          });
      }
    });
    return unsubscribe;
  }, []);
  // console.log("status: ", logged);
  const checkStorageLogout = () => {
    if (localStorage.getItem("user-bl-vn") === null) {
      throw new Error("Can not logout!");
    } else {
      setLogged("empty-user");
      localStorage.removeItem("user-bl-vn");
    }
  };

  const checkStorageLogin = (id) => {
    if (localStorage.getItem("user-bl-vn") !== null) {
      throw new Error("Can not login!");
    } else {
      setLogged("success");
      localStorage.setItem("user-bl-vn", JSON.stringify(id));
    }
  };
  const setLogginHandler = (userId) => {
    try {
      checkStorageLogin(userId);
    } catch (err) {
      console.log(err);
    }
  };
  const setLogoutHandler = () => {
    try {
      checkStorageLogout();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <AdminAuthContext.Provider
      value={{
        token: localToken,
        logStatus: logged,
        setLoggin: setLogginHandler,
        setLogout: setLogoutHandler,
      }}
    >
      {props.children}
    </AdminAuthContext.Provider>
  );
};

export default AdminAuthContext;

// const storeName = "firebaseLocalStorageDb";
// const storeTrans = "firebaseLocalStorage";
// const id =
//   "firebase:authUser:AIzaSyDjHheqTxuZBx5dBcMJBhY5PqR1RqzNIm0:[DEFAULT]";

// async function loadFromIndexedDB(storeName, storeTrans, id) {
//   return new Promise(function (resolve, reject) {
//     const dbRequest = indexedDB.open(storeName);

//     dbRequest.onerror = function (event) {
//       reject(Error("Error text"));
//     };

//     dbRequest.onupgradeneeded = function (event) {
//       // Objectstore does not exist. Nothing to load
//       event.target.transaction.abort();
//       reject(Error("Not found"));
//     };

//     dbRequest.onsuccess = function (event) {
//       const database = event.target.result;
//       const transaction = database.transaction([storeTrans]);
//       const objectStore = transaction.objectStore(storeTrans);
//       const objectRequest = objectStore.get(id);

//       objectRequest.onerror = function (event) {
//         reject(Error("Error text"));
//       };

//       objectRequest.onsuccess = function (event) {
//         if (objectRequest.result) resolve(objectRequest.result);
//         else reject(Error("object not found"));
//       };
//     };
//   });
// }
