import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import {
  responsiveProductDetailContentFontSize,
  responsiveLinkFontSize,
} from "../../styles/Styles";

const DetailGlasses = (props) => {
  const { pri1, sec1, pri2, sec2, detail } = props;
  const buttonSX = {
    backgroundColor: "#007053",
    "&.MuiButtonBase-root:hover": {
      background: "#00e6a8",
    },
    borderRadius: 5,
    paddingTop: 1.5,
    paddingBottom: 1.5,
    paddingLeft: 3,
    paddingRight: 3,
  };
  return (
    <Stack direction="column" justifyContent="center" spacing={1}>
      <Stack direction="row" justifyContent="center" spacing={1}>
        <Typography
          fontSize={responsiveProductDetailContentFontSize}
          variant="h4"
          sx={{ fontWeight: "bold" }}
        >
          {pri1}:
        </Typography>
        <Typography
          fontSize={responsiveProductDetailContentFontSize}
          variant="h4"
        >
          {sec1}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="center" spacing={1}>
        <Typography
          fontSize={responsiveProductDetailContentFontSize}
          variant="h4"
          sx={{ fontWeight: "bold" }}
        >
          {pri2}:
        </Typography>
        <Typography
          fontSize={responsiveProductDetailContentFontSize}
          variant="h4"
        >
          {sec2}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="center">
        <Typography
          fontSize={responsiveProductDetailContentFontSize}
          variant="h4"
          sx={{ fontStyle: "italic" }}
        >
          {detail}
        </Typography>
      </Stack>
      <Box display="inline-block">
        <a
          style={{
            textDecoration: "none",
          }}
          href="tel:0903422353"
        >
          <Button sx={buttonSX}>
            <Typography
              fontSize={responsiveLinkFontSize}
              variant="body1"
              sx={{ fontWeight: "bold", color: "#FFFFFF" }}
            >
              GỌI NGAY
            </Typography>
          </Button>
        </a>
      </Box>
    </Stack>
  );
};

export default DetailGlasses;
