import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, ThemeProvider } from "@mui/material";
import theme from "../styles/Styles";
const Loading = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          top: "40%",
          left: "47%",
        }}
      >
        <CircularProgress color="primary" size="5vw" />
      </Box>
    </ThemeProvider>
  );
};

export default Loading;
