import React from "react";
import ContentLayout from "../components/layout/ContentLayout";
import { useParams } from "react-router-dom";
import CreateBlog from "../components/admin/blog/CreateBlog";
import EditBlog from "../components/admin/blog/EditBlog";
import DeleteBlog from "../components/admin/blog/DeleteBlog";

const CreateUpdateBlog = () => {
  const params = useParams();
  const type = params.modifyType;
  let modifyContent;
  if (type === "create") {
    modifyContent = <CreateBlog />;
  }
  if (type === "edit") {
    modifyContent = <EditBlog />;
  }
  if (type === "delete") {
    modifyContent = <DeleteBlog />;
  }
  return (
    <ContentLayout>
      {modifyContent}
    </ContentLayout>
  );
};

export default CreateUpdateBlog;
