import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  TwitterShareButton,
  EmailShareButton,
  FacebookShareButton,
  TwitterIcon,
  FacebookIcon,
  EmailIcon,
} from "react-share";
import LinkIcon from "@mui/icons-material/Link";

const ContentFooter = () => {
  const copyLinkHandler = () => {
    navigator.clipboard.writeText(window.location.href);
  };
  return (
    <Stack direction="row" justifyContent="flex-start" spacing={1.2}>
      <Box sx={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
        <Typography variant="h6" sx={{ fontSize: { xs: "16px" } }}>
          Chia sẻ
        </Typography>
      </Box>
      <FacebookShareButton
        style={{ display: "flex", alignItems: "center" }}
        url={window.location.href}
      >
        <FacebookIcon size={30} round={true}></FacebookIcon>
      </FacebookShareButton>
      <TwitterShareButton
        url={window.location.href}
        style={{ display: "flex", alignItems: "center" }}
      >
        <TwitterIcon size={30} round={true}></TwitterIcon>
      </TwitterShareButton>
      <EmailShareButton
        url={window.location.href}
        style={{ display: "flex", alignItems: "center" }}
      >
        <EmailIcon size={30} round={true}></EmailIcon>
      </EmailShareButton>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          "&:hover": {
            cursor: "pointer",
          },
        }}
        onClick={copyLinkHandler}
      >
        <LinkIcon></LinkIcon>
      </Box>
    </Stack>
  );
};

export default ContentFooter;
