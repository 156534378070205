import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
const BackToTopButton = () => {
  const [isScrollToTop, setIsScrollToTop] = useState(false);

  useEffect(() => {
    document.body.addEventListener("scroll", () => {
      if (document.body.scrollTop > 200) {
        setIsScrollToTop(true);
      } else {
        setIsScrollToTop(false);
      }
    });
  }, []);
  const onBackToTopHandler = () => {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <React.Fragment>
      {isScrollToTop && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "40px",
            height: "40px",
            cursor: "pointer",
            position: "absolute",
            right: { xs: "15px", md: "30px" },
            zIndex: 3000,
            bottom: { xs: "15px", md: "30px" },
            borderRadius: "50%",
            backgroundColor: "#007053",
            opacity: 0.8,
          }}
          onClick={onBackToTopHandler}
        >
          <KeyboardDoubleArrowUpIcon
            fontSize="small"
            sx={{ color: "#FFFFFF" }}
          />
        </Box>
      )}
    </React.Fragment>
  );
};

export default BackToTopButton;
