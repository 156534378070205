import { createContext, useState } from "react";

const LoadingCtx = createContext({ isLoading: false, setIsLoading: () => {} });

export const LoadingCtxProvider = (props) => {
  const [loading, setLoading] = useState(false);
  const setLoadingHandler = (status) => {
    setLoading(status);
  };
  return (
    <LoadingCtx.Provider
      value={{ isLoading: loading, setIsLoading: setLoadingHandler }}
    >
      {props.children}
    </LoadingCtx.Provider>
  );
};

export default LoadingCtx;
