import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyDjHheqTxuZBx5dBcMJBhY5PqR1RqzNIm0",
  authDomain: "bl-v2-2022.firebaseapp.com",
  databaseURL: "https://bl-v2-2022-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "bl-v2-2022",
  storageBucket: "bl-v2-2022.appspot.com",
  messagingSenderId: "333378308650",
  appId: "1:333378308650:web:915911d2b9d62a23cb9153"
};

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyACPL0xTNWaX6VN5As-v4Shp7uG6uO-vnU",
//   authDomain: "bullard-project.firebaseapp.com",
//   projectId: "bullard-project",
//   storageBucket: "bullard-project.appspot.com",
//   messagingSenderId: "146661711413",
//   appId: "1:146661711413:web:8747eed0e502d1914f1b7c",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const database = getFirestore(app);
export const auth = getAuth(app);
