import { Box, Grid } from "@mui/material";
import React from "react";
import ImageProduct from "../../image/ImageProduct";
import Text from "./Text";
import background from "../../../image/background2.png";

const Feature = (props) => {
  const { featureText, image } = props;
  return (
    <Grid
      container
      sx={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        borderRadius: 5,
        padding: "30px",
        marginBottom: "32px",
        height: { md: "500px", lg: "600px" },
        overflow: "hidden",
      }}
    >
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          textAlign: "left",
          margin: "auto",
          mb: { xs: "50px", md: "auto" },
        }}
      >
        <Text featureText={featureText} />
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          pl: { xs: "0px", sm: "50px", md: "20px" },
          pr: { xs: "0px", sm: "50px", md: "20px" },
          margin: "auto",
        }}
      >
        <Box
          sx={{
            position: "relative",
            left: { xs: "0px", sm: "0px", md: "0px" },
            textAlign: "center",
            margin: "auto",
            width: { xs: "140vw", sm: "120vw", md: "100%", lg: "80%" },
          }}
        >
          <ImageProduct image={image.src} alt = {image.alt} id={image.id} width="100%" />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Feature;
