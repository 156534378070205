import { createContext, useState } from "react";

const EditCtx = createContext({ id: '', setId: (id) => {} });

const EditCtxProvider = (props) => {
  const [blogId, setBlogId] = useState("");
  const setIdHandler = (id) => {
    setBlogId(id);
  };

  return (
    <EditCtx.Provider value={{ id: blogId, setId: setIdHandler }}>
      {props.children}
    </EditCtx.Provider>
  );
};

export { EditCtxProvider };

export default EditCtx;
