import React from "react";
import { Typography } from "@mui/material";
import { responsiveContentOnCardFontSize } from "../../../../../styles/Styles";

const Content = (props) => {
  const { content } = props;
  return (
    <Typography
      fontSize={responsiveContentOnCardFontSize}
      variant="body1"
      sx={{ fontWeight: "light", color: "#2D3748", textAlign: {xs: 'center'} }}
    >
      {content}
    </Typography>
  );
};

export default Content;
