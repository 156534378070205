import { Box } from "@mui/material";
import React from "react";
import Header from "./header/Header";
import ProductGrid from "./product-grid/ProductGrid";

const ProductList = (props) => {
  const { unitProducts } = props;
  return (
    <Box sx={{ marginBottom: "30px" }}>
      <Header />
      <ProductGrid unitProducts={unitProducts} />
    </Box>
  );
};

export default ProductList;
