import { Typography } from "@mui/material";
import React from "react";
import Footer from "./components/footer/Footer";
import LandingPage from "./components/landing-page/LandingPage";
import BoldText from "./components/layout/BoldText";
import { responsiveContentFooterFontSize } from "./styles/Styles";

function App() {
  const contentFooter = (
    <Typography fontSize={responsiveContentFooterFontSize} variant="body1">
      Bullard là thương hiệu đến từ Mỹ chuyền về trang thiết bị an toàn lao
      động. Thiết kế của Bullard luôn hướng tới tính{" "}
      <BoldText> an toàn cao, bền bỉ, và hiện đại</BoldText>
    </Typography>
  );
  const quote = "";
  return (
    <React.Fragment>
      <LandingPage />
      <Footer content={contentFooter} quote={quote} />
    </React.Fragment>
  );
}

export default App;
