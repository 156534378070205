import { AppBar, Box, Typography } from "@mui/material";
import React from "react";
import { responsiveIntroFontSize } from "../../../styles/Styles";
import Layout from "../../layout/Layout";

const Intro = (props) => {
  const { pos, content } = props;
  return (
    <AppBar
      position="sticky"
      sx={{
        justifyContent: "center",
        backgroundColor: "#007053",
        pt: 1,
        pb: 1,
      }}
    >
      <Layout>
        <Box sx={{ textAlign: `${pos}` }}>
          <Typography
            fontSize={responsiveIntroFontSize}
            variant="h6"
            sx={{ color: "white", fontWeight: 400 }}
          >
            {content}
          </Typography>
        </Box>
      </Layout>
    </AppBar>
  );
};

export default Intro;
