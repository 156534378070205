import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import CardBlogMain from "../blog-card/CardBlogMain";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../../styles/Styles";
import { getDocs, collection } from "firebase/firestore";
import { database } from "../../../firebase-lib/initFireBase";

const BlogFooter = () => {
  const navigate = useNavigate();
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    getBlog();
  }, []);

  const getBlog = async () => {
    const blogCollectionRef = collection(database, "blogs");
    await getDocs(blogCollectionRef)
      .then((response) => {
        const myBlogs = response.docs
          .map((doc) => ({
            data: doc.data(),
            id: doc.id,
          }))
          .slice(0, 3)
          .filter((blog) => blog.data.archive === false);
        setBlog(myBlogs);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const onSeeArticlesHandler = () => {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
    navigate(`/blog`);
  };

  return (
    <Box sx={{ padding: 5, bgcolor: "#d1d2d4", mb: 0 }}>
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Typography variant="h1" sx={{ fontSize: "24px" }}>
            BLOG GẦN ĐÂY
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4} sx={{ justifyContent: "space-evenly" }}>
            {blog.map((post) => (
              <Grid key={post.id} item xs={12} sm={10} md={4}>
                <CardBlogMain
                  key={post.id}
                  id={post.id}
                  img={post.data.img}
                  category={post.data.category}
                  title={post.data.title}
                  subtitle={post.data.subtitle}
                  date={post.data.date}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          justifyContent="center"
          textAlign="center"
          alignItems="center"
        >
          <ThemeProvider theme={theme}>
            <Button
              sx={{
                size: "small",
                backgroundColor: "#007053",
                "&.MuiButtonBase-root:hover": {
                  background: "#00e6a8",
                },
                borderRadius: 5,
                paddingTop: 1.5,
                paddingBottom: 1.5,
                paddingLeft: 3,
                paddingRight: 3,
              }}
              onClick={onSeeArticlesHandler}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: "16px", color: "white" }}
              >
                Xem tất cả các bài viết
              </Typography>
            </Button>
          </ThemeProvider>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BlogFooter;
