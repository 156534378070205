import { Stack } from "@mui/material";
import React from "react";
import LineButton from "./LineButton";
import RoundButton from "./RoundButton";

const GridButtonOnCard = (props) => {
  const { id, colorBelow, colorAbove } = props;
  return (
    <Stack
      direction="row"
      spacing={3}
      sx={{ justifyContent: { xs: "center" } }}
    >
      <LineButton id={id} colorBelow={colorBelow} colorAbove={colorAbove} />
      <RoundButton id={id} />
    </Stack>
  );
};

export default GridButtonOnCard;
