import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import { responsiveDetailFontSize } from "../../../styles/Styles";
import styles from "./dropdown.module.css";

const Dropdown = () => {
  const dividerSX = {
    mt: "15px",
    mb: "15px",
  };
  const navLinkStyles = ({ isActive }) => {
    return {
      color: isActive ? "#007053" : "#AEB0B4",
      textDecoration: isActive ? "none" : "none",
    };
  };
  return (
    <Box sx={{ display: "flex", justifyContent: "center", gap: "3rem" }}>
      <Box className={styles.dropdown}>
        <Typography
          fontSize={responsiveDetailFontSize}
          variant="body1"
          sx={{
            color: "#2d3748",
            "&:hover": {
              color: "#007053",
            },
          }}
        >
          MŨ (NÓN) BẢO HỘ
        </Typography>
        <Box className={styles["dropdown-box"]}>
          <NavLink style={navLinkStyles} to="/san-pham/mu-bao-ho-bullard-s51">
            <Typography
              className={styles.link}
              fontSize={responsiveDetailFontSize}
              variant="body1"
              sx={{ color: "#FFFFFF" }}
            >
              Mũ Bảo Hộ S51
            </Typography>
          </NavLink>
          <Divider sx={dividerSX} />
          <NavLink style={navLinkStyles} to="/san-pham/mu-bao-ho-bullard-duo-safety-c422">
            <Typography
              className={styles.link}
              fontSize={responsiveDetailFontSize}
              variant="body1"
            >
              Mũ Bảo Hộ DUO SAFETY
            </Typography>
          </NavLink>
          <Divider sx={dividerSX} />
          <NavLink style={navLinkStyles} to="/san-pham/flexgen">
            <Typography
              className={styles.link}
              fontSize={responsiveDetailFontSize}
              variant="body1"
            >
              Tất cả các sản phẩm Mũ Bảo Hộ
            </Typography>
          </NavLink>
        </Box>
      </Box>
      <Box className={styles.dropdown}>
        <Typography
          fontSize={responsiveDetailFontSize}
          variant="body1"
          sx={{
            color: "#2d3748",
            "&:hover": {
              color: "#007053",
            },
          }}
        >
          KÍNH BẢO HỘ
        </Typography>
        <Box className={styles["dropdown-box"]}>
          <NavLink style={navLinkStyles} to="san-pham/kinh-bao-ho-bullard-se2">
            <Typography
              className={styles.link}
              fontSize={responsiveDetailFontSize}
              variant="body1"
            >
              Kính Bảo Hộ SE2
            </Typography>
          </NavLink>
        </Box>
      </Box>
      <Box className={styles.dropdown}>
        <NavLink style={navLinkStyles} to="/blog">
          <Typography
            fontSize={responsiveDetailFontSize}
            variant="body1"
            sx={{
              color: "#2d3748",
              "&:hover": {
                color: "#007053",
              },
            }}
          >
            BULLARD BLOG
          </Typography>
        </NavLink>
      </Box>
    </Box>
  );
};

export default Dropdown;
