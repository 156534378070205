import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import {
  responsiveProductDetailTitleFontSize,
  responsiveProductDetailContentFontSize,
} from "../../../styles/Styles";

const FirstDetail = () => {
  return (
    <Grid
      container
      justifyContent="center"
      spacing={5}
      sx={{ alignItems: "center" }}
    >
      <Grid item xs={12} lg={6}>
        <img
          src="https://res.cloudinary.com/dtkdsevtm/image/upload/v1605201286/images/se2-both-colors_v0gmrw.jpg"
          alt="Kính bảo hộ SE2 từ Mỹ | Chống xước, Chống Đọng Nước và Chống Va Đập"
          width="100%"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box display="flex" justifyContent="center">
          <Stack direction="column" justifyContent="space-between">
            <Typography
              fontSize={responsiveProductDetailTitleFontSize}
              variant="h3"
              sx={{ color: "#2D3748", mb: 0.5 , fontWeight: "bold"}}
            >
              AN TOÀN VÀ THOẢI MÁI
            </Typography>
            <Typography
              fontSize={responsiveProductDetailContentFontSize}
              variant="body1"
              sx={{ color: "#646E73" }}
            >
              Thiết kế tối đa sự thoải mái, phù hợp cho mọi lĩnh vực người dùng.
              Kiểu dáng ôm kín mắt và hai bên mặt giúp người dùng có tầm nhìn
              bao quát nhưng vẫn mang sự bảo vệ cho mắt. Không chỉ cho mắt,
              thiết kế vừa vặn với các tấm đệm bổ trợ để tạo sự thoải mái cho
              tai và mũi của người dùng. Kính bảo hộ được thiết kế ưu tiên sự an
              toàn và thoải mái của bạn
            </Typography>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FirstDetail;
