import React, { useContext, useEffect, useState } from "react";
import EditCtx from "../../../contexts/EditCtx";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../../styles/Styles";
import ContentLayout from "../../../components/layout/ContentLayout";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { deleteDoc, doc, getDoc } from "firebase/firestore";
import { database } from "../../../firebase-lib/initFireBase";
import { useNavigate } from "react-router-dom";
import RefreshCtx from "../../../contexts/RefreshCtx";
import LoadingCtx from "../../../contexts/LoadingCtx";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

const DeleteBlog = () => {
  const navigate = useNavigate();
  const editCtx = useContext(EditCtx);
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [category, setCategory] = useState("");
  const [content, setContent] = useState("");
  const docRef = doc(database, "blogs", editCtx.id);
  const refreshCtx = useContext(RefreshCtx);
  const loadingCtx = useContext(LoadingCtx);

  useEffect(() => {
    getDoc(docRef, editCtx.id).then((response) => {
      const myBlog = response.data();
      setTitle(myBlog?.title);
      setDate(myBlog?.date);
      setCategory(myBlog?.category);
      setContent(myBlog?.content);
    });
  }, []);
  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (title === "" || date === "" || category === "") {
      alert("Empty fill!");
      return;
    }
    deleteBlog();
  };

  const deleteBlog = async () => {
    loadingCtx.setIsLoading(true);
    await deleteDoc(docRef).catch((error) => {
      alert(error);
    });
    loadingCtx.setIsLoading(false);
    refreshCtx.setIsModify(!refreshCtx.isModify);
    navigate("/dashboard/admin");
  };

  const onCancelHandler = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/dashboard/admin");
  };

  const buttonSX = {
    "&.MuiButtonBase-root:hover": {
      background: "none",
    },
  };
  return (
    <ContentLayout>
      <ThemeProvider theme={theme}>
        <form onSubmit={onSubmitHandler}>
          <Card>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                  <Typography
                    variant="h1"
                    color="primary"
                    sx={{ fontSize: "48px" }}
                  >
                    DELETE BLOG
                  </Typography>
                </Grid>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                  <TextField
                    label="Title"
                    disabled
                    placeholder="Enter title"
                    fullWidth
                    value={title}
                  ></TextField>
                </Grid>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                  <TextField
                    id="date"
                    disabled
                    label="Date"
                    type="date"
                    value={date}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                  <TextField
                    label="Category"
                    disabled
                    placeholder="Enter category"
                    fullWidth
                    value={category}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h2"
                    sx={{ fontSize: 24, fontWeight: "bold" }}
                  >
                    Content
                  </Typography>
                  <Box
                    sx={{
                      border: 0.5,
                      display: "flex",
                      justifyContent: "flex-start",
                      padding: 2
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ fontSize: 16 }}
                      dangerouslySetInnerHTML={{ __html: content }}
                    ></Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                  <Stack direction="row" justifyContent="space-between">
                    <Button sx={buttonSX} onClick={onCancelHandler}>
                      <Typography variant="h6" sx={{ fontSize: 24 }}>
                        BACK
                      </Typography>
                    </Button>
                    <Button type="submit" sx={buttonSX}>
                      <Typography variant="h6" sx={{ fontSize: 24 }}>
                        CONFIRM DELETE
                      </Typography>
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      </ThemeProvider>
    </ContentLayout>
  );
};

export default DeleteBlog;
