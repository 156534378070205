import { Box, Typography } from "@mui/material";
import React from "react";
import { responsiveProductDetailContentFontSize } from "../../styles/Styles";
import BoldText from "../layout/BoldText";

const ProdCardNoInter = (props) => {
  const { imgSrc, alt, content, hidden } = props;
  const cardContainerSX = {
    width: { xs: "100%", md: "800px" },
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: "#F4F4F4",
  };
  const imgContainerSX = {
    display: "flex",
    width: "100%",
    height: { xs: "auto", sm: "65%" },
  };
  const contentContainerSX = {
    height: { md: "35%" },
    padding: "15px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  };
  return (
    <Box sx={cardContainerSX}>
      <Box sx={imgContainerSX}>
      {hidden === true ? (
          <img
            src={imgSrc}
            alt={alt}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              filter: "grayscale(70%)",
              WebkitFilter: "grayscale(70%)",
            }}
          />
        ) : (
          <img
            src={imgSrc}
            alt={alt}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        )}
      </Box>
      <Box sx={contentContainerSX}>
        <Typography
          fontSize={responsiveProductDetailContentFontSize}
          variant="body1"
          sx={{ color: "#646E73", textAlign: "center" }}
        >
          <BoldText>{content.main}</BoldText>
          {content.submain}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProdCardNoInter;
