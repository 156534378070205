import { Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import DUOContent from "../components/DUO-SAFETY/DUOContent";
import Footer from "../components/footer/Footer";
import S51Content from "../components/S51/S51Content";
import SE2Content from "../components/SE2/SE2Content";
import BoldText from "../components/layout/BoldText";
import {
  responsiveContentFooterFontSize,
  responsiveTitleFooterFontSize,
} from "../styles/Styles";
import NotFoundPage from "./NotFoundPage";
import Intro from "../components/landing-page/noti/Intro";
import HatList from "../components/product-type-list/HatList";
import { Helmet } from "react-helmet";
import Flexgen from "../components/image/flexgen.png";

const MuS51 =
  "https://res.cloudinary.com/dtkdsevtm/image/upload/v1597918835/images/s51-large_xeyygx.jpg";
const MuDUO =
  "https://res.cloudinary.com/dtkdsevtm/image/upload/v1597915399/images/duo-safety-large_fx7fdk.jpg";
const KinhSE2 =
  "https://res.cloudinary.com/dtkdsevtm/image/upload/v1605201286/images/se2-main-mobile_fq6rax.jpg";
const flexgen = Flexgen;
const Products = () => {
  const params = useParams();
  const { productName } = params;
  let content;
  let title;
  let description;
  let img;
  let keywords;
  let robots = "index, follow";
  if (productName === "flexgen") {
    const contentFooter = (
      <Typography fontSize={responsiveContentFooterFontSize} variant="body1">
        Bullard là thương hiệu đến từ Mỹ chuyền về trang thiết bị an toàn lao
        động. Thiết kế của Bullard luôn hướng tới tính{" "}
        <BoldText> an toàn cao, bền bỉ, và hiện đại</BoldText>
      </Typography>
    );
    content = (
      <React.Fragment>
        <Intro content="Bullard - Cầu mũ FlexGen™" pos="left" />
        <HatList />
        <Footer content={contentFooter} quote="" />
      </React.Fragment>
    );
    title = "Bullard - Cầu mũ FlexGen";
    description =
      "Khóa cầu mũ FlexGen được Bullard cải tiến từ khóa cầu mũ FlexGear giúp người dùng có thể thay đổi chiều rộng mũ dễ dàng hơn. FlexGen còn có tấm đệm sau tạo cảm giác thoải mái khi đội";
    keywords =
      "Bullard, Bullard S51, Mũ bảo hộ mỹ, nón bảo hộ mỹ, mũ bảo hộ điện lực, Khóa Flexgen, Cầu mũ Bullard mới, mũ Bullard mới, FlexGear";
    img = `${flexgen}`;
    robots = "index, nofollow";
  }

  // KINH SE2
  else if (productName === "kinh-bao-ho-bullard-se2") {
    const contentFooter = (
      <Typography fontSize={responsiveContentFooterFontSize} variant="body1">
        Bullard là thương hiệu đến từ Mỹ chuyền về trang thiết bị an toàn lao
        động. Thiết kế của Bullard luôn hướng tới tính{" "}
        <BoldText> an toàn cao, bền bỉ, và hiện đại</BoldText>
      </Typography>
    );
    content = (
      <React.Fragment>
        <Intro content="Kính Bảo Hộ SE2" pos="left" />
        <SE2Content />
        <Footer content={contentFooter} quote="" />
      </React.Fragment>
    );
    title = "Bullard - Kính bảo hộ SE2";
    description =
      "Kính bảo hộ SE2 là sản phẩm cần có với mỗi kỹ sư công nhân bởi khả năng chống tia UV, chầy xước và đặc biệt chống đọng sương. Sản phẩm đạt tiêu chuẩn ANSI của Mỹ cho kính bảo hộ";
    keywords =
      "Bullard, Bullard SE2, Kính bảo hộ chống tia UV, kính bảo hộ, kính bảo hộ chống xước, kính bảo hộ chống đọng sương";
    img = `${KinhSE2}`;

    // MU DUO
  } else if (productName === "mu-bao-ho-bullard-duo-safety-c422") {
    const contentFooter = (
      <Typography fontSize={responsiveContentFooterFontSize} variant="body1">
        Được sử dụng tại công trường như Đập Hoover hay Cầu Cổng Vàng, mũ bảo hộ
        của Bullard đều được thiết kế nhằm{" "}
        <BoldText>
          tối đa hiệu suất, sự thoải mái, chất lượng và độ bền.
        </BoldText>
      </Typography>
    );
    const quote = (
      <Typography
        fontSize={responsiveTitleFooterFontSize}
        variant="h6"
        sx={{ fontWeight: "bold" }}
      >
        WE INVENTED THE HARD HAT
      </Typography>
    );
    content = (
      <React.Fragment>
        <Intro
          content="Mũ Bảo Hộ Bullard DUO SAFETY | MADE IN USA"
          pos="left"
        />
        <DUOContent />
        <Footer content={contentFooter} quote={quote} />
      </React.Fragment>
    );
    title = "Bullard - Mũ bảo hộ Bullard DUO Safety C422";
    description =
      "Mũ bảo hộ DUO Safety C422 là dòng sản phẩm mới được Bullard phát triển với tính năng của mũ bảo hộ thế hệ trước nhưng có mức giá tiết kiệm hơn. Mũ phù hợp với đa ngành nghề đặc biệt xây dựng.";
    keywords =
      "Bullard, Bullard DUO Safety, Mũ bảo hộ mỹ giá rẻ, nón bảo hộ mỹ, mũ bảo hộ xây dựng, Flexgen,ANSI, CSA, EN, mũ bảo hộ cách điện, nón bảo hộ cách điện, C422";
    img = `${MuDUO}`;

    // MU S51
  } else if (productName === "mu-bao-ho-bullard-s51") {
    const contentFooter = (
      <Typography fontSize={responsiveContentFooterFontSize} variant="body1">
        Được sử dụng tại công trường như Đập Hoover hay Cầu Cổng Vàng, mũ bảo hộ
        của Bullard đều được thiết kế nhằm{" "}
        <BoldText>
          tối đa hiệu suất, sự thoải mái, chất lượng và độ bền.
        </BoldText>
      </Typography>
    );
    const quote = (
      <Typography
        fontSize={responsiveTitleFooterFontSize}
        variant="h6"
        sx={{ fontWeight: "bold" }}
      >
        WE INVENTED THE HARD HAT
      </Typography>
    );
    content = (
      <React.Fragment>
        <Intro content="Mũ Bảo Hộ S51 | MADE IN THE USA" pos="left" />
        <S51Content />
        <Footer content={contentFooter} quote={quote} />
      </React.Fragment>
    );
    title = "Bullard - Mũ bảo hộ S51";
    description =
      "Mũ bảo hộ S51 là dòng sản phẩm truyền thống của Bullard được tin dùng đặc biệt trong ngành điện với khả năng cách điện được kiểm nghiệm theo tiêu chuẩn ANSI của Mỹ, CSA của Canada và EN của Châu Âu.";
    keywords =
      "Bullard, Bullard S51, Mũ bảo hộ mỹ, nón bảo hộ mỹ, mũ bảo hộ điện lực, Flexgen,ANSI, CSA, EN, mũ bảo hộ cách điện, nón bảo hộ cách điện";
    img = `${MuS51}`;
  } else {
    content = <NotFoundPage />;
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="robots" content={robots} />
        <meta name="revisit-after" content="3 days" />
        <meta name="MobileOptimized" content="width" />
        <meta name="HandheldFriendly" content="true" />
        <meta property="og:site_name" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta property="og:image" content={img} />
        <meta property="og:image:url" content={img} />
        <meta property="og:image:secure_url" content={img} />
        <meta name="twitter:description" content={description} />
        <link rel="image_src" href={`${img}`} />
      </Helmet>
      {content}
    </React.Fragment>
  );
};

export default Products;
