import {
  AppBar,
  Grid,
  Stack,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import PlaceIcon from "@mui/icons-material/Place";
import MailIcon from "@mui/icons-material/Mail";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import Layout from "./../layout/Layout";
import {
  responsiveTitleFooterFontSize,
  responsiveContactFooterFontSize
} from "../../styles/Styles";

const StyleToolBar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "1rem",
  marginBottom: "1rem",
});

const Logo = styled(Box)({
  display: "inline-block",
  width: "100%",
  padding: 0,
  margin: 0,
});

const Infomation = styled(Stack)({
  justifyContent: "center",
  textAlign: "left",
  direction: "column",
});

const FooterMain = (props) => {
  const { content, quote } = props;
  return (
    <AppBar
      elevation={0}
      position="static"
      sx={{ backgroundColor: "#454545", height: "auto", pt: "5px", pb: "5px" }}
    >
      <Layout>
        <StyleToolBar disableGutters>
          <Grid container spacing={5} sx={{ alignItems: "center" }}>
            <Grid item xs={12} lg={9}>
              <Logo>
                <Box
                  sx={{
                    display: { xs: "flex", sm: "none", md: "none", lg: "none" },
                    width: "100%",
                    marginBottom: 1,
                  }}
                  textAlign="left"
                >
                  <img
                    src="https://www.bullard.vn/images/bullard_white_logo.png"
                    alt="BULLARD VIỆT NAM | DỤNG CỤ AN TOÀN TỪ MỸ"
                    width="40%"
                  />
                </Box>
                <Box
                  sx={{
                    display: { xs: "none", sm: "flex", md: "none", lg: "none" },
                    width: "100%",
                    marginBottom: 1,
                  }}
                  textAlign="left"
                >
                  <img
                    src="https://www.bullard.vn/images/bullard_white_logo.png"
                    alt="BULLARD VIỆT NAM | DỤNG CỤ AN TOÀN TỪ MỸ"
                    width="30%"
                  />
                </Box>
                <Box
                  sx={{
                    display: { xs: "none", md: "flex", lg: "none" },
                    width: "100%",
                    marginBottom: 1,
                  }}
                  textAlign="left"
                >
                  <img
                    src="https://www.bullard.vn/images/bullard_white_logo.png"
                    alt="BULLARD VIỆT NAM | DỤNG CỤ AN TOÀN TỪ MỸ"
                    width="22%"
                  />
                </Box>
                <Box
                  sx={{
                    display: { xs: "none", md: "none", lg: "flex" },
                    width: "100%",
                    marginBottom: 1,
                  }}
                  textAlign="left"
                >
                  <img
                    src="https://www.bullard.vn/images/bullard_white_logo.png"
                    alt="BULLARD VIỆT NAM | DỤNG CỤ AN TOÀN TỪ MỸ"
                    width="20%"
                  />
                </Box>
                {quote}
                {content}
              </Logo>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Infomation>
                <Box sx={{ display: "inline-block", textAlign: "left" }}>
                  <Typography
                    fontSize={responsiveTitleFooterFontSize}
                    variant="h6"
                    sx={{ fontWeight: "bold", mb: 1 }}
                  >
                    CÔNG TY TNHH NGỌC DUY
                  </Typography>
                </Box>
                <Stack direction="row" spacing={1} sx={{ mb: 0.5 }}>
                  <PlaceIcon fontSize="small" />
                  <Typography
                    fontSize={responsiveContactFooterFontSize}
                    variant="body1"
                  >
                    232 Tôn Đức Thắng, Đống Đa, Hà Nội
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1} sx={{ mb: 0.5 }}>
                  <MailIcon fontSize="small" />
                  <Typography
                    fontSize={responsiveContactFooterFontSize}
                    variant="body1"
                  >
                    info@ngocduy.com
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <PhoneInTalkIcon fontSize="small" />
                  <a
                    style={{
                      textDecoration: "none",
                      color: "#2d3748",
                    }}
                    href="tel:0903422353"
                  >
                    <Typography
                      fontSize={responsiveContactFooterFontSize}
                      variant="body1"
                      sx={{ color: "#007BFF" }}
                    >
                      (090) 342 2353
                    </Typography>
                  </a>
                </Stack>
              </Infomation>
            </Grid>
          </Grid>
        </StyleToolBar>
      </Layout>
    </AppBar>
  );
};

export default FooterMain;
