import React from "react";
import { Typography } from "@mui/material";
import { responsiveContentFontSize } from "../../../../styles/Styles";

const Content = (props) => {
  const { content } = props;
  return (
    <Typography
      fontSize={responsiveContentFontSize}
      variant="body1"
      sx={{ color: "#FFFFFF", textAlign: { xs: "center", md: "left" } }}
    >
      {content}
    </Typography>
  );
};

export default Content;
