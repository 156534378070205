import { Box } from "@mui/material";
import React from "react";
import Intro from "./Intro";
import WorkHour from "./WorkHour";

const Noti = (props) => {
  const { content, pos } = props;
  return (
    <>
      <Intro content={content} pos = {pos}/>
      <Box sx={{ marginBottom: "30px" }}>
        <WorkHour />
      </Box>
    </>
  );
};

export default Noti;
