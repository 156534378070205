import { Box, Stack, styled, Toolbar, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { NavLink, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme, { responsiveDetailFontSize } from "../../styles/Styles";
import MenuBurger from "./MenuBurger";
import Layout from "../layout/Layout";
import Dropdown from "./dropdown/Dropdown";

const Hotline = styled(Stack)(({ theme }) => ({
  direction: "column",
  textAlign: "center",
  justifyContent: "center",
}));

const Navbar = () => {
  const navigate = useNavigate();
  const onClickHandler = () => {
    navigate("/");
  };
  const navLinkStyles = ({ isActive }) => {
    return {
      color: isActive ? "#007053" : "#AEB0B4",
      textDecoration: isActive ? "none" : "none",
    };
  };
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <AppBar
          elevation={0}
          position="static"
          sx={{ backgroundColor: "white", margin: 0, padding: 0 }}
        >
          <Toolbar disableGutters>
            <NavLink style={navLinkStyles} to="/">
              <Box
                sx={{
                  width: "220px",
                  textAlign: "center",
                  display: { xs: "none", lg: "flex" },
                  mt: 2,
                  mb: 2,
                }}
                onClick={onClickHandler}
              >
                <img
                  src="https://www.bullard.vn/images/bullard_logo.png"
                  alt="logo"
                  width="100%"
                  height="auto"
                  style={{ objectFit: "contain" }}
                />
              </Box>
            </NavLink>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", lg: "none" },
                justifyContent: "left",
              }}
            >
              <MenuBurger />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", lg: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Dropdown />
            </Box>
            <Box
              sx={{
                width: "140px",
                textAlign: "center",
                display: { xs: "flex", lg: "none" },
                mt: 2,
                mb: 2,
                justifyContent: "center",
              }}
              onClick={onClickHandler}
            >
              <img
                src="https://www.bullard.vn/images/bullard_logo.png"
                alt="logo"
                width="100%"
                height="auto"
                style={{ objectFit: "contain" }}
              />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", lg: "none" },
                justifyContent: "right",
                alignItems: "center",
              }}
            >
              <a
                style={{
                  textDecoration: "none",
                  color: "#2d3748",
                  display: "flex",
                  alignItems: "center",
                }}
                href="tel:0903422353"
              >
                <LocalPhoneIcon
                  sx={{ margin: "auto" }}
                  fontSize="small"
                  color="primary"
                />
              </a>
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: "none", lg: "flex" } }}>
              <Hotline>
                <Stack
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  spacing={1}
                >
                  <LocalPhoneIcon fontSize="small" color="primary" />
                  <Typography
                    fontSize={responsiveDetailFontSize}
                    variant="body1"
                    sx={{
                      fontWeight: "light",
                      display: "flex",
                      gap: 1,
                      color: "#282829",
                    }}
                  >
                    (090) 342 2353
                  </Typography>
                </Stack>
                <a
                  style={{
                    textDecoration: "none",
                  }}
                  href="tel:0903422353"
                >
                  <Typography
                    fontSize={responsiveDetailFontSize}
                    variant="body1"
                    sx={{ color: "#007053" }}
                  >
                    Hotline tư vấn sản phẩm
                  </Typography>
                </a>
              </Hotline>
            </Box>
          </Toolbar>
        </AppBar>
      </Layout>
    </ThemeProvider>
  );
};

export default Navbar;
