import {
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ContentLayout from "../../../components/layout/ContentLayout";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../../styles/Styles";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { database } from "../../../firebase-lib/initFireBase";
import { useNavigate } from "react-router-dom";
import EditCtx from "../../../contexts/EditCtx";
import RefreshCtx from "../../../contexts/RefreshCtx";
import LoadingCtx from "../../../contexts/LoadingCtx";
import ReactQuill from "react-quill";
import { quillModules } from "../../rich_text_editor/QuillEditor";
import "react-quill/dist/quill.snow.css";
import {
  responsiveTitleFontSize,
  responsiveSubtitleFontSize,
} from "../../../styles/Styles";

const EditBlog = () => {
  const navigate = useNavigate();
  const editCtx = useContext(EditCtx);
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [category, setCategory] = useState("");
  const [content, setContent] = useState("");
  const docRef = doc(database, "blogs", editCtx.id);
  const refreshCtx = useContext(RefreshCtx);
  const loadingCtx = useContext(LoadingCtx);

  const onChangeTitleHandler = (event) => {
    setTitle(event.target.value);
  };
  const onChangeCategoryHandler = (event) => {
    setCategory(event.target.value);
  };
  const onChangeDateHandler = (event) => {
    setDate(event.target.value);
  };
  const onGetDataHandler = (data) => {
    setContent(data);
  };

  useEffect(() => {
    getDoc(docRef, editCtx.id).then((response) => {
      const myBlog = response.data();
      setTitle(myBlog?.title);
      setDate(myBlog?.date);
      setCategory(myBlog?.category);
      setContent(myBlog?.content);
    });
  }, []);
  const updateBlog = async () => {
    loadingCtx.setIsLoading(true);
    refreshCtx.setIsModify(false);
    await updateDoc(docRef, { title, date, category, content }).catch(
      (error) => {
        alert(error);
      }
    );
    loadingCtx.setIsLoading(false);
    refreshCtx.setIsModify(true);
    navigate("/dashboard/admin");
  };
  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (title === "" || date === "" || category === "") {
      alert("Empty fill!");
      return;
    }
    updateBlog();
  };

  const onCancelHandler = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/dashboard/admin");
  };

  const buttonSX = {
    "&.MuiButtonBase-root:hover": {
      background: "none",
    },
  };
  return (
    <ContentLayout>
      <ThemeProvider theme={theme}>
        <form onSubmit={onSubmitHandler}>
          <Card>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                  <Typography
                    fontSize={responsiveTitleFontSize}
                    variant="h3"
                    color="primary"
                  >
                    Edit blog
                  </Typography>
                </Grid>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                  <TextField
                    label="Title"
                    placeholder="Enter title"
                    fullWidth
                    value={title}
                    onChange={onChangeTitleHandler}
                  ></TextField>
                </Grid>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                  <TextField
                    id="date"
                    label="Date"
                    type="date"
                    value={date}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={onChangeDateHandler}
                  />
                </Grid>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                  <TextField
                    label="Category"
                    placeholder="Enter category"
                    fullWidth
                    value={category}
                    onChange={onChangeCategoryHandler}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h2"
                    sx={{ fontSize: 24, fontWeight: "bold" }}
                  >
                    Content
                  </Typography>
                  <ReactQuill
                    modules={quillModules}
                    value={content}
                    onChange={onGetDataHandler}
                    theme="snow"
                  />
                </Grid>
                <Grid item xs={12} justifyContent="center" textAlign="center">
                  <Stack direction="row" justifyContent="space-between">
                    <Button sx={buttonSX} onClick={onCancelHandler}>
                      <Typography
                        fontSize={responsiveSubtitleFontSize}
                        variant="h4"
                      >
                        Back
                      </Typography>
                    </Button>
                    <Button type="submit" sx={buttonSX}>
                      <Typography
                        fontSize={responsiveSubtitleFontSize}
                        variant="h4"
                      >
                        Confirm edit
                      </Typography>
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      </ThemeProvider>
    </ContentLayout>
  );
};

export default EditBlog;
