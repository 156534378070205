import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  responsiveProductDetailTitleFontSize,
  responsiveProductDetailContentFontSize,
} from "../../../styles/Styles";

const ThirdDetail = () => {
  return (
    <Grid
      container
      justifyContent="center"
      spacing={5}
      sx={{ alignItems: "center", mt: 8 }}
    >
      <Grid item xs={12}>
        <Box>
          <img
            src="https://salt.tikicdn.com/ts/tmp/f4/25/52/602746de06cac1631a41785e34d71265.jpg"
            alt="Nguồn Gốc của Mũ Bullard S51"
            width="100%"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          fontSize={responsiveProductDetailTitleFontSize}
          variant="h3"
          sx={{ color: "#2D3748" , fontWeight: "bold"}}
        >
          XUẤT XỨ RÕ RÀNG
        </Typography>
        <Typography
          fontSize={responsiveProductDetailContentFontSize}
          variant="body1"
          sx={{
            color: "#646E73",
            display: "flex",
            gap: 2,
            alignItems: "center",
            mt: 2,
          }}
        >
          <FiberManualRecordIcon fontSize="small" />
          Mũ Bullard S51 tự hào được sản xuất tại Mỹ. Dòng chữ MADE IN THE USA
          được đúc nổi rõ nét mặt sau mũ.
        </Typography>
        <Typography
          fontSize={responsiveProductDetailContentFontSize}
          variant="body1"
          sx={{
            color: "#646E73",
            display: "flex",
            gap: 2,
            alignItems: "center",
          }}
        >
          <FiberManualRecordIcon fontSize="small" />
          Mũ Bullard S51 được nhập khẩu chính hãng và đầy đủ giấy tờ CO/CQ.
        </Typography>
        <Typography
          fontSize={responsiveProductDetailContentFontSize}
          variant="body1"
          sx={{
            color: "#646E73",
            display: "flex",
            gap: 2,
            alignItems: "center",
            mt: 2,
          }}
        >
          Mũ Bullard S51 là sản phẩm bán chạy nhất trong sản phẩm cùng phân
          khúc. Tuy nhiên, Mũ Bullard S51 được làm giả và nhái nhiều trên thị
          trường. Để tránh điều này, quý khách hãy mua tại đại lý chính hãng của
          Bullard tại Việt Nam.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ThirdDetail;
