import React from "react";
import { Button, Typography } from "@mui/material";
import { responsiveLinkFontSize } from "../../../../styles/Styles";

const RoundButton = () => {
  const buttonSX = {
    backgroundColor: "#007053",
    "&.MuiButtonBase-root:hover": {
      background: "#00e6a8",
    },
    borderRadius: 5,
    paddingTop: 1.5,
    paddingBottom: 1.5,
    paddingLeft: 3,
    paddingRight: 3,
  };
  return (
    <a
      style={{
        textDecoration: "none",
        color: "#2d3748",
      }}
      href="tel:0903422353"
    >
      <Button sx={buttonSX}>
        <Typography
          fontSize={responsiveLinkFontSize}
          variant="body1"
          sx={{ fontWeight: "bold", color: "#FFFFFF" }}
        >
          Gọi ngay
        </Typography>
      </Button>
    </a>
  );
};

export default RoundButton;
