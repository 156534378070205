import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#007053",
    },
    secondary: {
      main: "#282829",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiSnackbar: {
      variants: [
        {
          props: { variant: "sendMessage" },
          style: {
            "& .MuiSnackbarContent-root": {
              background: "#007053",
            },
          },
        },
      ],
    },
  },
});

export const responsiveTitleFontSize = {
  lg: 54,
  md: 45,
  sm: 45,
  xs: 45,
};
export const responsiveProductDetailTitleFontSize = {
  lg: 32,
  md: 30,
  sm: 30,
  xs: 28,
};
export const responsiveProductDetailTitleFontSize2 = {
  lg: 32,
  md: 32,
  sm: 22,
  xs: 20,
};
export const responsiveBigTitleFontSize = {
  lg: 36,
  md: 36,
  sm: 36,
  xs: 33,
};

export const responsiveTitleOnCardFontSize = {
  lg: 28,
  md: 26,
  sm: 30,
  xs: 28,
};
export const responsiveTitleOnBannerFontSize = {
  lg: 30,
  md: 28,
  sm: 26,
  xs: 19,
};
export const responsiveSubtitleOnBannerFontSize = {
  lg: 24,
  md: 22,
  sm: 22,
  xs: 15,
};
export const responsiveContentOnCardFontSize = {
  lg: 20,
  md: 16,
  sm: 24,
  xs: 19,
};
export const responsiveContentFontSize = {
  lg: 18,
  md: 16,
  sm: 18,
  xs: 16,
};
export const responsiveColorFontSize = {
  lg: 20,
  md: 19,
  sm: 19,
  xs: 17,
};
export const responsiveProductDetailContentFontSize = {
  lg: 18,
  md: 16,
  sm: 18,
  xs: 16,
};
export const responsiveIntroFontSize = {
  lg: 15,
  md: 15,
  sm: 15,
  xs: 15,
};

export const responsiveSubtitleFontSize = {
  lg: 24,
  md: 22,
  sm: 22,
  xs: 20,
};

export const responsiveLinkFontSize = {
  lg: 16,
  md: 12,
  sm: 14,
  xs: 13,  
};

export const responsiveDetailFontSize = {
  lg: 16,
  md: 16,
  sm: 19,
  xs: 14,
};
export const responsiveWorkHourFontSize = {
  lg: 13,
  md: 13,
  sm: 13,
  xs: 13,
};
export const responsiveFeatureFontSize = {
  lg: 24,
  md: 24,
  sm: 24,
  xs: 21,
};
export const responsiveFeature2FontSize = {
  lg: 16,
  md: 25,
  sm: 20,
  xs: 14,
};
export const responsiveTitleFooterFontSize = {
  lg: 20,
  md: 20,
  sm: 18,
  xs: 18,
};
export const responsiveContentFooterFontSize = {
  lg: 20,
  md: 20,
  sm: 20,
  xs: 18,
};
export const responsiveContactFooterFontSize = {
  lg: 15,
  md: 15,
  sm: 15,
  xs: 15,
};

export const responsiveCateBlogFontSize = {
  lg: 14,
  md: 14,
  sm: 14,
  xs: 12,
};
export const responsiveTitleBlogFontSize = {
  lg: 20,
  md: 20,
  sm: 20,
  xs: 18,
};
export const responsiveContentBlogFontSize = {
  lg: 16,
  md: 16,
  sm: 15,
  xs: 14,
};
export const responsiveDateBlogFontSize = {
  lg: 14,
  md: 14,
  sm: 14,
  xs: 12,
};

export const responsiveTitleSliderFontSize = {
  lg: 40,
  md: 40,
  sm: 40,
  xs: 35,
};
export const responsiveSubtitleSliderFontSize = {
  lg: 28,
  md: 28,
  sm: 28,
  xs: 23,
};
export const responsivePageTitleFontSize = {
  lg: 28,
  md: 28,
  sm: 28,
  xs: 20,
};

export const themeButton = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

export default theme;
