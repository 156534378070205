import { Box, Typography } from "@mui/material";
import React from "react";
import {
  responsiveProductDetailContentFontSize,
  responsiveProductDetailTitleFontSize,
} from "../../styles/Styles";
import DropdownProduct from "./DropdownProduct";

const SecondDetailList = (props) => {
  const { prods, hatType } = props;
  const detailWrapper = {
    width: "100%",
    mt: 8,
    // mb: 4,
  };
  return (
    <Box sx={detailWrapper}>
      <Typography
        fontSize={responsiveProductDetailTitleFontSize}
        variant="h3"
        sx={{ color: "#2D3748", textAlign: "center" , fontWeight: "bold"}}
      >
        HỆ THỐNG CẦU MŨ
      </Typography>
      <Typography
        fontSize={responsiveProductDetailContentFontSize}
        variant="body1"
        sx={{ color: "#646E73", textAlign: "center" }}
      >
        Mũ có tới 3 sự lựa chọn về cầu mũ cho người dùng:
      </Typography>
      <DropdownProduct prods={prods} hatType={hatType}></DropdownProduct>
    </Box>
  );
};

export default SecondDetailList;
