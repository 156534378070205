import React from "react";
import ReactDOM from "react-dom/client";
import { AdminAuthContextProvider } from "./contexts/AdminAuthContext";

import MainApp from "./MainApp";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AdminAuthContextProvider>
    <MainApp />
  </AdminAuthContextProvider>
);
