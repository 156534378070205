import { Box } from "@mui/material";
import React from "react";
import parse from "html-react-parser";
import styles from "./content.module.css";
import AdBanner from "../../../smart-ad-banner/AdBanner";

const ContentMain = (props) => {
  const { content } = props;
  const finalContent = content
    .replace(/<iframe/gi, `$& class = ${styles.video}`)
    .replace(/<img/gi, `$& className = ${styles.image}`)
    .replace(/<u/gi, `$& className = ${styles["u"]}`)
    .replace(/<o/gi, `$& className = ${styles["o"]}`)
    .replace(/<ul/gi, `$& className = ${styles["ul"]}`)
    .replace(/<ol/gi, `$& className = ${styles["ol"]}`)
    .replace(/<li/gi, `$& className = ${styles.list}`)
    .replace(/<a/gi, `$& className = ${styles.link}`)
    .replace(/<em/gi, `$& className = ${styles["em"]}`)
    .replace(/<sup/gi, `$& className = ${styles["sup"]}`)
    .replace(/<sub/gi, `$& className = ${styles["sub"]}`)
    .replace(/class="ql-align-center"/gi, `style = "text-align:center;"`)
    .replace(/class="ql-align-right"/gi, `style = "text-align:right;"`)
    .replace(/class="ql-align-justify"/gi, `style = "text-align:justify;"`)
    .replace(
      /class="ql-syntax"/gi,
      `style = "background-color: #1a1a1a; color:white; padding:20px; border-radius:5px; line-height:30px; font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;"`
    )
    .replace(/<h2/gi, `$& className = ${styles["subtitle-2"]}`)
    .replace(/<h3/gi, `$& className = ${styles["subtitle-3"]}`)
    .replace(/<h4/gi, `$& className = ${styles["subtitle-4"]}`)
    .replace(/<h5/gi, `$& className = ${styles["subtitle-5"]}`)
    .replace(/<h6/gi, `$& className = ${styles["subtitle-6"]}`)
    .replace(/<span/gi, `$& className = ${styles.content}`)
    .replace(/<p/gi, `$& className = ${styles.content}`)
    .replace(/<h1/gi, `$& className = ${styles.title}`)
    .replace(
      /<blockquote/gi,
      `$& className = ${styles.content} style = "border-left:4px solid gray; padding-left: 20px; padding-right: 20px"`
    );
  // .replace(/<strong/gi, `$& className = ${styles.strong}`)
  // console.log(finalContent);
  return (
    <React.Fragment>
      <AdBanner type={["hard-hat", "glasses"]} />
      <Box sx={{ mt: 4 }}>{parse(finalContent)}</Box>
      <AdBanner type={["glasses"]} />
    </React.Fragment>
  );
};

export default ContentMain;
