import React, { useContext } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../styles/Styles";
import { useNavigate } from "react-router-dom";
import { responsiveTitleFontSize } from "../../styles/Styles";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase-lib/initFireBase";
import AdminAuthContext from "../../contexts/AdminAuthContext";

const AdminHeader = () => {
  const navigate = useNavigate();
  const adminAuthCtx = useContext(AdminAuthContext);
  const buttonSX = {
    "&.MuiButtonBase-root:hover": {
      background: "none",
    },
  };

  const onCreateHandler = () => {
    navigate("create");
  };

  const onSignoutHandler = async () => {
    await signOut(auth)
      .then(() => {
        adminAuthCtx.setLogout();
      })
      .catch(() => {
        console.log("Error on logging out");
      });
    navigate("/", { replace: true });
  };

  return (
    <Grid container>
      <ThemeProvider theme={theme}>
        <Grid item xs={12} sm={8} sx={{ textAlign: "left" }}>
          <Typography
            fontSize={responsiveTitleFontSize}
            variant="h3"
            sx={{ color: "#2D3748" }}
          >
            Blogs
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ textAlign: "right" }}>
          <Button sx={buttonSX} onClick={onSignoutHandler}>
            <Box
              sx={{
                color: "#2D3748",
              }}
            >
              <Typography fontSize={responsiveTitleFontSize} variant="h3">
                Sign out
              </Typography>
            </Box>
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ textAlign: "center", justifyContent: "flex-start" }}
        >
          <Button sx={buttonSX} onClick={onCreateHandler}>
            <Box sx={{ padding: 2, bgcolor: "#d1d2d4", borderRadius: 7 }}>
              <Typography fontSize={responsiveTitleFontSize} variant="h3">
                Create blog
              </Typography>
            </Box>
          </Button>
        </Grid>
      </ThemeProvider>
    </Grid>
  );
};

export default AdminHeader;
