import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { responsiveProductDetailContentFontSize } from "../../styles/Styles";

const ItemGrid = (props) => {
  const { content, iconSrc, alt } = props;
  return (
    <Grid item xs={12} sm={4} sx={{ mb: 2 }}>
      <Box display="inline-block" textAlign="center">
        <img
          src={iconSrc}
          width="80%"
          alt={alt}
          style={{ objectFit: "contains" }}
        />
      </Box>
      <Typography
        fontSize={responsiveProductDetailContentFontSize}
        variant="body1"
        sx={{ color: "#2D3748" }}
      >
        {content}
      </Typography>
    </Grid>
  );
};

export default ItemGrid;
