import { Divider, Typography } from "@mui/material";
import { useEffect } from "react";
import AdminMain from "../components/admin/AdminMain";
import Footer from "../components/footer/Footer";
import BoldText from "../components/layout/BoldText";
import { responsiveContentFooterFontSize } from "../styles/Styles";
const Admin = () => {
  useEffect(() => {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const contentFooter = (
    <Typography fontSize={responsiveContentFooterFontSize} variant="body1">
      Bullard là thương hiệu đến từ Mỹ chuyền về trang thiết bị an toàn lao
      động. Thiết kế của Bullard luôn hướng tới tính{" "}
      <BoldText> an toàn cao, bền bỉ, và hiện đại</BoldText>
    </Typography>
  );
  return (
    <div>
      <AdminMain />
      <Divider sx={{ mt: 5, mb: 5, backgroundColor: "#007053" }} />
      <Footer content={contentFooter} quote="" />
    </div>
  );
};

export default Admin;
