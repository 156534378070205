import { Box, Typography } from "@mui/material";
import React from "react";
import Footer from "../components/footer/Footer";
import Intro from "../components/landing-page/noti/Intro";
import BoldText from "../components/layout/BoldText";
import { responsiveContentFooterFontSize } from "../styles/Styles";
const NotFoundPage = () => {
  const contentFooter = (
    <Typography fontSize={responsiveContentFooterFontSize} variant="body1">
      Bullard là thương hiệu đến từ Mỹ chuyền về trang thiết bị an toàn lao
      động. Thiết kế của Bullard luôn hướng tới tính{" "}
      <BoldText> an toàn cao, bền bỉ, và hiện đại</BoldText>
    </Typography>
  );
  return (
    <>
      <Intro
        content="THƯƠNG HIỆU ĐẾN TỪ MỸ VỚI HƠN 120 NĂM KINH NGHIỆM."
        pos="center"
      />
      <Box textAlign="center">
        <Typography variant="h1" sx={{ fontSize: "120px", color: "#2D3748" }}>
          404
        </Typography>
        <Typography variant="h1" sx={{ fontSize: "45px", color: "#2D3748" }}>
          Not Found
        </Typography>
      </Box>
      <Footer content={contentFooter} quote="" />
    </>
  );
};

export default NotFoundPage;
