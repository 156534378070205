import { Box, Collapse, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { responsiveLinkFontSize } from "../../../../styles/Styles";

const LineButton = (props) => {
  const { id, colorBelow, colorAbove } = props;
  const [checked, setChecked] = useState(true);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  const navLinkStyles = ({ isActive }) => {
    return {
      color: isActive ? "#282829" : `${colorBelow}`,
      textDecoration: isActive ? "none" : "none",
      fontWeight: isActive ? "normal" : "normal",
      verticalAlign: "middle",
    };
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        margin: "auto",
        marginLeft: 0,
        marginRight: 0,
      }}
      onMouseOver={handleChange}
      onMouseOut={handleChange}
    >
      <NavLink style={navLinkStyles} to={`/san-pham/${id}`}>
        <Stack direction="column" sx={{ width: "100%", textAlign: "center" }}>
          <Typography
            fontSize={responsiveLinkFontSize}
            variant="body1"
            sx={{ fontWeight: "bold", color: `${colorAbove}` }}
          >
            ĐỌC THÊM
          </Typography>
          <Collapse
            orientation="horizontal"
            in={checked}
            sx={{
              display: "inline-block",
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "2px",
                backgroundColor: "#007053",
              }}
            >
              <Typography
                fontSize={responsiveLinkFontSize}
                variant="body1"
                sx={{
                  fontWeight: "light",
                  color: `${colorBelow}`,
                  visibility: "hidden",
                }}
              >
                ĐỌC THEM
              </Typography>
            </Box>
          </Collapse>
        </Stack>
      </NavLink>
    </Box>
  );
};

export default LineButton;
