import { Grid } from "@mui/material";
import React from "react";
import Layout from "../layout/Layout";
import AdminHeader from "./AdminHeader";
import AdminTable from "./AdminTable";

const AdminMain = (props) => {
  return (
    <Layout>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Grid item xs={12}>
          <AdminHeader />
        </Grid>
        <Grid item xs={12}>
          <AdminTable />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default AdminMain;
