import { Container } from "@mui/material";

const Layout = (props) => {
  return (
    <Container sx={{ marginTop: `${props.marginTop}`, backgroundColor: "none", maxWidth:"1350px" }} maxWidth="false">
      {props.children}
    </Container>
  );
};

export default Layout;
