import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import {
  responsiveProductDetailTitleFontSize,
  responsiveProductDetailContentFontSize,
} from "../../../styles/Styles";
import BoldText from "../../layout/BoldText";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const FifthDetail = () => {
  return (
    <Grid
      container
      justifyContent="center"
      spacing={5}
      sx={{ alignItems: "center", mt: 8 }}
    >
      <Grid item xs={12}>
        <Box>
          <img
            src="https://salt.tikicdn.com/ts/tmp/f3/09/3b/9b3b1e062a019ea8f98e01bb201ecf17.jpg"
            alt="Tem Bảo Hành và Tem Hợp Quy của Mũ DUO Safety bởi Bullard"
            width="100%"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          fontSize={responsiveProductDetailTitleFontSize}
          variant="h3"
          sx={{ color: "#2D3748" , fontWeight: "bold"}}
        >
          TIÊU CHUẨN
        </Typography>
        <Typography
          fontSize={responsiveProductDetailContentFontSize}
          variant="body1"
          sx={{
            color: "#646E73",
            display: "flex",
            gap: 2,
            alignItems: "center",
            mt: 2,
          }}
        >
          <BoldText>
          Toàn bộ mũ nhập khẩu đều được kiểm tra chất lượng, đạt tiêu chuẩn và quy chuẩn theo quy định của chính phủ Việt Nam.
          </BoldText>
        </Typography>
        <Typography
          fontSize={responsiveProductDetailContentFontSize}
          variant="body1"
          sx={{
            color: "#646E73",
            display: "flex",
            gap: 2,
            alignItems: "center",
            mt: 2,
          }}
        >
          Tem Đại Lý Chính Hãng và Chứng Nhận Hợp Quy cho phép Mũ DUO Safety bởi Bullard được lưu thông tại Việt Nam được dán phía sau mũ. Mũ DUO Safety bởi Bullard đã đạt những tiêu chuẩn Việt Nam và quốc tế:
        </Typography>
        <Typography
          fontSize={responsiveProductDetailContentFontSize}
          variant="body1"
          sx={{
            color: "#646E73",
            display: "flex",
            gap: 2,
            alignItems: "center",
            mt: 1,
          }}
        >
          <FiberManualRecordIcon fontSize="small" />
          Tiêu chuẩn VIỆT NAM TCVN 6407-1998
        </Typography>
        <Typography
          fontSize={responsiveProductDetailContentFontSize}
          variant="body1"
          sx={{
            color: "#646E73",
            display: "flex",
            gap: 2,
            alignItems: "center",
            mt: 1,
          }}
        >
          <FiberManualRecordIcon fontSize="small" />
          Quy Chuẩn Hợp Quy VIỆT NAM QCVN 06:2012/BLĐTBXH
        </Typography>
        <Typography
          fontSize={responsiveProductDetailContentFontSize}
          variant="body1"
          sx={{
            color: "#646E73",
            display: "flex",
            gap: 2,
            alignItems: "center",
            mt: 1,
          }}
        >
          <FiberManualRecordIcon fontSize="small" />
          Tiêu Chuẩn MỸ ANSI/ISEA: Z89.1-2014, Tuýp I, Loại E và G
        </Typography>
        
        
      </Grid>
    </Grid>
  );
};

export default FifthDetail;
