import { Box } from "@mui/material";
import React from "react";

const BlogHeader = (props) => {
  const { media } = props;
  return (
    <Box sx={{ height: { xs: "100%", sm: "700px" } }}>
      <img
        height="100%"
        width="100%"
        src={media}
        alt="thumbnail"
        style={{ objectFit: "contain" }}
      />
    </Box>
  );
};

export default BlogHeader;
