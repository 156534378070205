import React from "react";
import { Typography } from "@mui/material";
import { responsiveContentFontSize } from "../../../../styles/Styles";

const Header = (props) => {
  const { header } = props;
  return (
    <Typography
      fontSize={responsiveContentFontSize}
      variant="body1"
      sx={{
        fontWeight: "bold",
        textAlign: { xs: "center", md: "left" },
        color: "#007053",
      }}
    >
      {header}
    </Typography>
  );
};

export default Header;
