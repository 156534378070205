import { Box, Button, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import {
  responsiveProductDetailContentFontSize,
  responsiveProductDetailTitleFontSize2,
} from "../../styles/Styles";
import AccordionProd from "./AccordionProd";

const ProdCard2 = (props) => {
  const { hatType, prods } = props;
  const detailRef = useRef();
  const [selected, setSelected] = useState(null);
  const [accoChange1, setAccoChange1] = useState(false);
  const [accoChange2, setAccoChange2] = useState(false);
  const [accoChange3, setAccoChange3] = useState(false);
  const [isFirstPC1, setIsFirstPC1] = useState(false);
  const [isFirstPC2, setIsFirstPC2] = useState(false);
  const [isFirstPC3, setIsFirstPC3] = useState(false);
  const [currentTypePC, setCurrentTypePC] = useState(null);
  const typoTitleSX = {
    color: "#2D3748",
    fontWeight: 600,
    textAlign: "center",
  };
  const typoReadmoreSX = {
    fontWeight: 500,
    color: "#007053",
    textAlign: "center",
    "&:hover": {
      cursor: "pointer",
    },
  };
  const readmoreContainerSX = {
    width: "100%",
    marginTop: "auto",
    display: "flex",
    justifyContent: "space-around",
  };
  const imgContainerSX = {
    display: "flex",
    width: "100%",
  };
  const contentContainerSX = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    mb: "20px",
  };
  const prodContainerSX = {
    mb: "30px",
  };
  const closeAccordionHandler = (type) => {
    if (type === "1") {
      setIsFirstPC1(false);
    }
    if (type === "2") {
      setIsFirstPC2(false);
    }
    if (type === "3") {
      setIsFirstPC3(false);
    }
  };
  const detailChangeHandlerPC = (type) => {
    if (type === "1") {
      if (isFirstPC1 === true) {
        if (currentTypePC !== type) {
          setCurrentTypePC(type);
          console.log("1");
          setAccoChange1((prev) => {
            return !prev;
          });
        }
      } else {
        setIsFirstPC2(false);
        setIsFirstPC3(false);
        setIsFirstPC1(true);
        setCurrentTypePC(type);
      }
    } else if (type === "2") {
      if (isFirstPC2 === true) {
        if (currentTypePC !== type) {
          setCurrentTypePC(type);
          console.log("2");
          setAccoChange2((prev) => {
            return !prev;
          });
        }
      } else {
        setIsFirstPC1(false);
        setIsFirstPC3(false);
        setIsFirstPC2(true);
        setCurrentTypePC(type);
      }
    } else if (type === "3") {
      if (isFirstPC3 === true) {
        if (currentTypePC !== type) {
          setCurrentTypePC(type);
          console.log("3");
          setAccoChange3((prev) => {
            return !prev;
          });
        }
      } else {
        setIsFirstPC1(false);
        setIsFirstPC2(false);
        setIsFirstPC3(true);
        setCurrentTypePC(type);
      }
    }
  };
  const handleChange = (type, i) => {
    if (selected === i) {
      setSelected(null);
    }
    detailChangeHandlerPC(type);
    const detail = document.getElementById(`detail-${type}`);
    setTimeout(() => {
      detail.scrollIntoView({ behavior: "smooth" });
    }, 0);
    setSelected(i);
  };
  return (
    <>
      {prods.map((prod, index) => {
        return (
          <Box ref={detailRef} key={index} sx={prodContainerSX}>
            <Box sx={imgContainerSX}>
              <img
                src={prod.imgSrc}
                alt={prod.alt}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
            <Box sx={contentContainerSX}>
              <Typography
                fontSize={responsiveProductDetailTitleFontSize2}
                variant="body1"
                sx={typoTitleSX}
              >
                {prod.title}
              </Typography>
              <Typography
                fontSize={responsiveProductDetailContentFontSize}
                variant="body1"
                sx={{
                  color: "#2D3748",
                  textAlign: "center",
                  fontWeight: 400,
                }}
              >
                {prod.subtitle}
              </Typography>
            </Box>
            <Box
              id={`detail-${prod.type}`}
              ref={detailRef}
              sx={readmoreContainerSX}
            >
              <Button>
                <Typography
                  fontSize={responsiveProductDetailContentFontSize}
                  variant="body1"
                  sx={typoReadmoreSX}
                  onClick={() => {
                    handleChange(prod.type, index);
                  }}
                >
                  Đọc thêm về loại {prod.type}
                </Typography>
              </Button>
            </Box>
            {(prod.type === "1"
              ? isFirstPC1
              : prod.type === "2"
              ? isFirstPC2
              : isFirstPC3) && (
              <AccordionProd
                hatType={hatType}
                accoClass={`${prod.type}`}
                type={prod.type}
                change={
                  prod.type === "1"
                    ? accoChange1
                    : prod.type === "2"
                    ? accoChange2
                    : accoChange3
                }
                onCloseAccordion={closeAccordionHandler}
              />
            )}
            {/* <Box
              ref={detailRef}
              className={
                selected === index ? styles["content show"] : styles["content"]
              }
              sx={{
                height: "auto",
                border: selected === index ? "3px solid #007053" : "none",
                padding: selected === index ? "20px" : 0,
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Box
                sx={clearIconSX}
                onClick={() => {
                  setSelected(-1);
                }}
              >
                <ClearIcon fontSize="medium" />
              </Box>
              <Box>
                {prod.type === "1" ? (
                  <DetailType1 />
                ) : prod.type === "2" ? (
                  <DetailType2 />
                ) : (
                  <DetailType3 />
                )}
              </Box>
            </Box> */}
          </Box>
        );
      })}
    </>
  );
};

export default ProdCard2;
