import { Grid } from "@mui/material";
import React from "react";
import ContentFooter from "./ContentFooter";
import ContentHeader from "./ContentHeader";
import ContentMain from "./ContentMain";

const BlogContent = (props) => {
  const { title, subtitle, date, category, content } = props;
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ContentHeader
            title={title}
            subtitle={subtitle}
            date={date}
            category={category}
          />
        </Grid>
        <Grid item xs={12}>
          <ContentMain content={content}  />
        </Grid>
        <Grid item xs={12}>
          <ContentFooter />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default BlogContent;
