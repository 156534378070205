import React, { useEffect } from "react";
import { Divider, Stack } from "@mui/material";
import Layout from "../layout/Layout";
import theme from "../../styles/Styles";
import { ThemeProvider } from "@mui/material";
import FirstDetail from "./detail-grid/FirstDetail";
import ThirdDetail from "./detail-grid/ThirdDetail";
import FifthDetail from "./detail-grid/FifthDetail";
import S51Color from "./S51Color";
import RepresentProduct from "../represent-product/RepresentProduct";
import AdBanner from "../smart-ad-banner/AdBanner";
import MuS51 from "../image/muvang.png";
import SecondDetailList from "../features/SecondDetailList";
import FourthDetailList from "../features/FourthDetailList";
import Dayquaixanh from "../image/daycai-bullard-xanh.jpg";
import Dayquaitrang from "../image/daycai-bullard-trang.jpg";
import Dayquaivang from "../image/daycai-bullard-vang.jpg";
import Flexgear from "../image/flexgear-bullard.jpg";
import Chotgai from "../image/chotgai-bullard.jpg";
import Numvan from "../image/numvan-bullard.jpg";

const prods = [
  {
    title: "Núm vặn FlexGen™",
    subtitle: "Nhẹ. Dễ thao tác. Thiết kế mới",
    imgSrc: `${Numvan}`,
    alt: "Cầu Mũ FlexGen - Mũ bảo hộ S51",
    type: "1",
  },
  {
    title: "Núm vặn Flex-Gear®",
    subtitle: "To bản. Bền lâu. Chắc chắn",
    imgSrc: `${Flexgear}`,
    alt: "Cầu Mũ FlexGear - Mũ bảo hộ S51",
    type: "2",
  },
  {
    title: "Chốt gài",
    subtitle: "Tối giản. Tiết kiệm",
    imgSrc: `${Chotgai}`,
    alt: "Cầu Mũ Chốt Gài - Mũ bảo hộ S51",
    type: "3",
  }
];
const prods2 = [
  {
    imgSrc: `${Dayquaitrang}`,
    alt: "Dây Quai Cầu Sập của Mũ bảo hộ S51",
    content: {
      main: "Cầu Sập",
      submain:
        ": Mũ bảo hộ S51 trang bị khóa Cầu Sập tăng độ chắc chắn cho dây quai.",
    },
    hidden: false,
  },
  {
    imgSrc: `${Dayquaixanh}`,
    alt: "Dây Quai Móc Cài của Mũ bảo hộ S51",
    content: {
      main: "Khoá Móc Cài",
      submain:
        ": Dây quai Khóa Móc Cài được thiết kế tối ưu cho việc dễ tháo lắp và tối đa khoảng sử dụng để lắp thêm phụ kiện.",
    },
    hidden: false,
  },
  {
    imgSrc: `${Dayquaivang}`,
    alt: "Dây Quai Móc Bấm của Mũ bảo hộ S51",
    content: {
      main: "[NGƯNG SẢN XUẤT] Khoá Móc Bấm",
      submain:
        ": Khóa Móc Bấm có thiết kế tối giản và dễ sử dụng.",
    },
    hidden: false,
  }
];

const S51Content = () => {
  const bhldLink = "https://baoholaodong.com/nhan-hang/bullard/?utm_source=bvn";
  const tikiLink =
    "https://tiki.vn/mu-bao-ho-bullard-s51-num-van-xuat-xu-hoa-ki-hang-chinh-hang-p117091649.html";
  useEffect(() => {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Layout>
          <RepresentProduct
            title="MŨ BẢO HỘ S51"
            name="mu-bao-ho-bullard-s51"
            imageSrc={MuS51}
            alt="Mũ Bullard S51 | Thiết bị an toàn từ Mỹ - Hơn 120 năm kinh nghiệm"
            content="[Tương thích với cầu mũ mới FlexGen™] Chuyên dụng cho ngành điện, mũ bảo hộ S51 sở hữu thiết kế kiểu Mỹ, khác biệt với các mũ khác trên thị trường Việt Nam."
            bhldLink={bhldLink}
            tikiLink={tikiLink}
          />
          <Divider />
          <Stack direction="column" justifyContent="center">
            {/* Detail 1  */}
            <FirstDetail />
            {/* Detail 2 */}
            <SecondDetailList hatType="s51" prods={prods} />
            <AdBanner type={["hard-hat", "glasses"]} />
            {/* Detail 3 */}
            <ThirdDetail />
            {/* Detail 4 */}
            <FourthDetailList prods={prods2} />
            {/* Detail 5 */}
            <FifthDetail />
            {/* Color */}
            <S51Color />
          </Stack>
        </Layout>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default S51Content;
