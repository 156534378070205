import { Box, Stack } from "@mui/material";
import React from "react";
import ButtonGroup from "../../button/ButtonGroup";
import Content from "./Content";
import Header from "./Header";
import Title from "./Title";

const Text = (props) => {
  const { featureText } = props;
  return (
    <Box>
      <Stack direction="column" spacing={2}>
        <Header header={featureText.header}></Header>
        <Title title={featureText.title}></Title>
        <Content content={featureText.content}></Content>
        <ButtonGroup id={featureText.id} equivalent = {featureText.equivalent} />
      </Stack>
    </Box>
  );
};

export default Text;
