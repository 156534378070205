import { Stack, Typography } from "@mui/material";
import React from "react";
import { responsiveProductDetailContentFontSize } from "../../../styles/Styles";
import ColorText from "../../features/ColorText";
import BoldText from "../../layout/BoldText";

const DetailType1 = () => {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      spacing={2}
      sx={{ width: "100%" }}
    >
      <Typography
        fontSize={responsiveProductDetailContentFontSize}
        variant="body1"
        sx={{ color: "#646E73" }}
      >
        <BoldText>
          <ColorText color="#007053">(Loại 1) </ColorText>
          Hệ Thống Núm Vặn FlexGen™ 4 điểm cài đúc liền dây cầu mũ.
        </BoldText>
      </Typography>
      <Typography
        fontSize={responsiveProductDetailContentFontSize}
        variant="body1"
        sx={{ color: "#646E73" }}
      >
        <BoldText>Tấm đệm trán:</BoldText> Miếng tạo êm trán phía trước mũ được
        phủ bề mặt lớp nhựa Vinyl không thấm nước. Vật liệu và lỗ thoáng trên
        tấm đệm sẽ khiến người dùng cảm thấy thoải mái, thoáng mát và mềm mại
        khi sử dụng.
      </Typography>
      <Typography
        fontSize={responsiveProductDetailContentFontSize}
        variant="body1"
        sx={{ color: "#646E73" }}
      >
        <BoldText>Núm Vặn FlexGen™:</BoldText> Với những cải tiến lớn so với dòng núm vặn Flex-Gear®, hệ thống núm vặn FlexGen™ sở hữu phần khóa hãm chắc nhưng linh hoạt hơn với biểu tưởng tam giác 3 cạnh đặc trưng của Bullard. Phía sau núm vặn là tấm đệm gáy đa điểm công thái học giúp tăng khả năng thoáng khí và độ chắc chắn của mũ khi đeo. Đặc biệt, tấm đệm công thái học giúp người dùng cảm thấy thoải mái khi đeo mà không có cảm giác vướng nhờ sử dụng vật liệu độ dẻo cao.
        Bản núm vặn to vừa tay giúp người chỉnh độ rộng ngang
        trong khoảng từ 52 đến 64cm (6 1/2" - 8").
      </Typography>
      <Typography
        fontSize={responsiveProductDetailContentFontSize}
        variant="body1"
        sx={{ color: "#646E73" }}
      >
        <BoldText>Dây Cầu Mũ Chữ X:</BoldText> Dây tiêu chuẩn của Bullard là dây
        Nylon bản ngang 1.9cm (3/4"). Đặc biệt, dây được đúc trực tiếp vào 4
        chốt cài ở bốn góc thay vì gài thủ công như các mũ khác trên thị trường
        tạo sự chắc chắn cho người sử dụng. Ở chốt cài ở mỗi góc sẽ có 2 nấc
        khóa giúp người dùng thay đổi độ cao/chiều sâu của lòng mũ một cách dễ
        dàng.
      </Typography>
    </Stack>
  );
};

export default DetailType1;
