import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import {
  responsiveLinkFontSize,
  responsiveContentFontSize,
} from "../../styles/Styles";
import LogoBhld from "../image/Vector Smart Object.png";

const ShopLink = (props) => {
  const { bhldLink, tikiLink, name } = props;
  const BoxSX = {
    p: { xs: "16px", lg: "16px" },
    borderWidth: "1px",
    border: 2,
    borderRadius: 2,
    borderColor: "#d9d9d9",
    "&:hover": {
      borderColor: "#737373",
      cursor: "pointer",
      transition: "700ms all",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  return (
    <Stack direction="column" spacing={2}>
      <a href={bhldLink} target="_blank">
        <Box sx={BoxSX}>
          <Box
            sx={{
              maxWidth: { md: "120px", lg: "280px" },
              height: { xs: "20px", lg: "24px" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <img
              src={LogoBhld}
              alt={name}
              height="100%"
              object-fit="contain"
            ></img>
          </Box>
        </Box>
      </a>
      <a href={tikiLink} target="_blank">
        <Box sx={BoxSX}>
          <Box
            sx={{
              maxWidth: { lg: "280px" },
              height: { xs: "20px", lg: "24px" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTj_TBVk5wZMmZc8cOCRkbhcx0RX7vGIwG3sFoOTjmLpOQbGm7bkMgxUP920xHwhe6itoo&usqp=CAU"
              alt="tiki"
              height="100%"
              object-fit="contain"
            ></img>
          </Box>
        </Box>
      </a>
      <Divider textAlign="center">
        <Typography
          fontSize={responsiveLinkFontSize}
          variant="body1"
          sx={{ fontWeight: "bold", color: "#2d3748" }}
        >
          HOẶC LIÊN HỆ TRỰC TIẾP
        </Typography>
      </Divider>
      <Box sx={BoxSX}>
        <Box
          sx={{
            maxWidth: { lg: "280px" },
            height: { xs: "20px", lg: "24px" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography
            fontSize={responsiveContentFontSize}
            variant="body1"
            sx={{ fontWeight: "medium", color: "#FFFFFF", textAlign: "center" }}
          >
            <a
              style={{
                textDecoration: "none",
                color: "#2d3748",
              }}
              href="tel:0903422353"
            >
              (090) 342 2353
            </a>
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};

export default ShopLink;
