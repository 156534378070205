import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { updateDoc, doc, getDocs, collection } from "firebase/firestore";
import { database } from "../../firebase-lib/initFireBase";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../styles/Styles";
import { useNavigate } from "react-router-dom";
import RefreshCtx from "../../contexts/RefreshCtx";
import LoadingCtx from "../../contexts/LoadingCtx";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditCtx from "../../contexts/EditCtx";
import CircularProgress from "@mui/material/CircularProgress";
import { responsiveSubtitleFontSize } from "../../styles/Styles";

const AdminTable = (props) => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const refreshCtx = useContext(RefreshCtx);
  const loadingCtx = useContext(LoadingCtx);
  const editCtx = useContext(EditCtx);

  useEffect(() => {
    getBlogs();
  }, [refreshCtx.isModify]);

  const getBlogs = async () => {
    const blogCollectionRef = collection(database, "blogs");
    await getDocs(blogCollectionRef)
      .then((response) => {
        const myBlogs = response.docs.map((doc) => ({
          data: doc.data(),
          id: doc.id,
        }));
        setBlogs(myBlogs);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const onRefreshHandler = async () => {
    loadingCtx.setIsLoading(true);
    await getBlogs();
    loadingCtx.setIsLoading(false);
  };

  const onEditHandler = () => {
    navigate("edit");
  };
  const onDeleteHandler = () => {
    navigate("delete");
  };
  const buttonSX = {
    "&.MuiButtonBase-root:hover": {
      background: "none",
    },
  };

  const handleChange = async (id, valueCheck) => {
    const value = !valueCheck;
    const docRef = doc(database, "blogs", id);
    await updateDoc(docRef, { archive: value }).catch((error) => {
      alert(error);
    });
    onRefreshHandler();
  };

  return (
    <ThemeProvider theme={theme}>
      <Button sx={buttonSX} onClick={onRefreshHandler}>
        <Box
          flexGrow={8}
          sx={{ padding: 2, bgcolor: "#d1d2d4", borderRadius: 7 }}
        >
          <Typography fontSize={responsiveSubtitleFontSize} variant="h4">
            Refresh
          </Typography>
        </Box>
      </Button>
      {!loadingCtx.isLoading ? (
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">TITLE</TableCell>
                <TableCell align="center">DATE</TableCell>
                <TableCell align="center">CATEGORY</TableCell>
                <TableCell align="center">EDIT</TableCell>
                <TableCell align="center">DELETE</TableCell>
                <TableCell align="center">ARCHIVE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {blogs.map((blog) => (
                <TableRow key={blog.id}>
                  <TableCell align="center" component="th" scope="row">
                    {blog.id}
                  </TableCell>
                  <TableCell align="center">{blog.data.title}</TableCell>
                  <TableCell align="center">{blog.data.date}</TableCell>
                  <TableCell align="center">{blog.data.category}</TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={() => {
                        editCtx.setId(blog.id);
                        onEditHandler();
                      }}
                    >
                      <EditIcon />
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={() => {
                        editCtx.setId(blog.id);
                        onDeleteHandler();
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox
                      checked={blog.data.archive}
                      onClick={() => {
                        const valueCheck = blog.data.archive;
                        handleChange(blog.id, valueCheck);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box>
          <CircularProgress color="primary" />
        </Box>
      )}
    </ThemeProvider>
  );
};

export default AdminTable;
