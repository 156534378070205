import {
  Box,
  Link,
  styled,
  Toolbar,
  Typography,
  AppBar,
  Grid,
} from "@mui/material";
import Layout from "./../layout/Layout";
import { responsiveContactFooterFontSize } from "../../styles/Styles";

const StyleToolBar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "1rem",
  marginBottom: "1rem",
});

const Detail = styled(Box)({});

const FooterSubMain = () => {
  return (
    <AppBar
      elevation={0}
      position="static"
      sx={{ backgroundColor: "#282829", height: "auto" }}
    >
      <Layout>
        <StyleToolBar disableGutters>
          <Grid
            container
            spacing={5}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} md={9}>
              <Detail>
                <Link href="https://ngocduy.com" underline="none" sx={{ color: "#b1b1b1" }}>
                  <Typography
                    fontSize={responsiveContactFooterFontSize}
                    variant="body1"
                  >
                    Về Ngọc Duy Co., Ltd
                  </Typography>
                </Link>
                <Link href="https://ngocduy.com" underline="none" sx={{ color: "#b1b1b1" }}>
                  <Typography
                    fontSize={responsiveContactFooterFontSize}
                    variant="body1"
                  >
                    Liên Hệ Hợp Tác
                  </Typography>
                </Link>
                <Link href="https://ngocduy.com" underline="none" sx={{ color: "#b1b1b1" }}>
                  <Typography
                    fontSize={responsiveContactFooterFontSize}
                    variant="body1"
                  >
                    Tin Tức
                  </Typography>
                </Link>
              </Detail>
            </Grid>
            <Grid item xs={12} lg = {3}>
                <Typography
                  fontSize={responsiveContactFooterFontSize}
                  variant="body1"
                  sx={{ color: "#b1b1b1" }}
                >
                  Copyrights © 2022 NGOC DUY CO.,
                </Typography>
                <Typography
                  fontSize={responsiveContactFooterFontSize}
                  variant="body1"
                  sx={{ color: "#b1b1b1" }}
                >
                  LTD. All right reserved
                </Typography>
            </Grid>
          </Grid>
        </StyleToolBar>
      </Layout>
    </AppBar>
  );
};

export default FooterSubMain;
