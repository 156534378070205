import React from "react";
import { Box, styled, Grid, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../../styles/Styles";
import {
  responsiveCateBlogFontSize,
  responsiveTitleBlogFontSize,
  responsiveContentBlogFontSize,
} from "../../../styles/Styles";

const CardContent = (props) => {
  const { title, subtitle, category } = props;
  const ContentContainer = styled(Grid)({});

  return (
    <ContentContainer container spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            pt: 1,
            pb: 2,
            pl: 2,
            pr: 2,
          }}
        >
          <ThemeProvider theme={theme}>
            {/* <Box
              backgroundColor="#007053"
              color="#FFFFFF"
              sx={{ display: "inline-flex", borderRadius: 4 }}
            > */}
              <Typography
                fontSize={responsiveCateBlogFontSize}
                sx={{ margin: "auto", color: "#007053",fontWeight: "bold" }}
                variant="body1"
              >
                {category.toUpperCase()}
              </Typography>
            {/* </Box> */}
          </ThemeProvider>

          <Typography
            fontSize={responsiveTitleBlogFontSize}
            variant="h6"
            sx={{ fontWeight: "bold", mb: 0, color: "#2D3748" }}
          >
            {title}
          </Typography>
          <Typography
            fontSize={responsiveContentBlogFontSize}
            variant="body1"
            sx={{ color: "#4d4d4d" }}
          >
            {subtitle.length > 190
              ? subtitle.substring(0, 150).concat("...")
              : subtitle}
          </Typography>
        </Box>
      </Grid>
    </ContentContainer>
  );
};
export default CardContent;
